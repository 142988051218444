<template>
  <div>
    <div class="weather-card" @click="openDialog">
      {{ updatedDate }}
      <div style="width: 100%; height: 100px;" class="d-flex align-center">
        <v-img :src="forecast_icon" contain width="100" height="100" />
      </div>
      <div class="d-flex flex-row justify-center">
        <div style="font-size: 14px;">
          {{ weather.temp.max }}&deg;{{ $parent.unit == "imperial" ? "F" : "C" }}
        </div>
        <div style="font-size: 13px; color: grey;" class="ml-3">
          {{ weather.temp.min }}&deg;{{ $parent.unit == "imperial" ? "F" : "C" }}
        </div>
      </div>
      <div
        class="d-flex align-center justify-center"
        style="font-size: 13px; font-weight: bold; margin-top: 5px;"
      >
        {{ getWeatherString }}
      </div>
    </div>

    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title class="headline">Weather Details</v-card-title>
        <v-card-text>
          <div>
            <p><strong>Date:</strong> {{ updatedDate }}</p>
            <p><strong>Max Temp:</strong> {{ weather.temp.max }}&deg;{{ $parent.unit == "imperial" ? "F" : "C" }}</p>
            <p><strong>Min Temp:</strong> {{ weather.temp.min }}&deg;{{ $parent.unit == "imperial" ? "F" : "C" }}</p>
            <p><strong>Description:</strong> {{ getWeatherString }}</p>
            <v-img :src="forecast_icon" contain width="100" height="100" />
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="closeDialog">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import dateFormat from "dateformat";
export default {
  props: {
    weather: Object
  },
  data() {
    return {
      dialog: false
    };
  },
  methods: {
    convertTZ(date, tzString) {
      if (!date) return "";
      return new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString(
          "en-US",
          {
            timeZone: tzString ? tzString : "America/New_York"
          }
        )
      );
    },
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    }
  },
  computed: {
    updatedDate() {
      if (!this.weather) return "";
      var date = new Date(this.weather.dt * 1000);
      return dateFormat(this.convertTZ(date), "ddd d");
    },
    forecast_icon() {
      let icon = "";
      try {
        icon = this.weather.weather[0]["icon"] || "01d";
      } catch {
        icon = "01d";
      }
      return require(`@/assets/weather/${icon}.png`);
      // return `http://openweathermap.org/img/wn/${icon}@2x.png`;
    },
    getWeatherString() {
      if (!this.weather) return "";
      if (this.weather.weather && this.weather.weather.length > 0) {
        let desc = this.weather.weather[0].description;
        return desc.charAt(0).toUpperCase() + desc.slice(1);
      }
      return "";
    }
  }
};
</script>
<style scoped>
.weather-card {
  padding: 12px;
  background: #ffffffdd;
  border-radius: 10px;
  cursor: pointer;
}
</style>
