<template>
  <v-container
    fluid
    style="height: 100%; margin:0px; padding:0px; min-height: inherit"
    class="d-flex flex-row"
  >
    <company-profile-left-section class="hidden-sm-and-down" />
    <company-profile-agencies-section />
  </v-container>
</template>
<script>
import CompanyProfileLeftSection from "../../company/profile/CompanyProfileLeftSection.vue";
import CompanyProfileAgenciesSection from "../../company/profile/CompanyProfileAgenciesSection.vue";
export default {
  components: { CompanyProfileLeftSection, CompanyProfileAgenciesSection }
};
</script>
