<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
    style="z-index:200000001;"
  >
    <v-card>
      <v-card-title
        style="display: flex; flex-direction: row; justify-content: space-between; font-family: 'Poppins-SemiBold'"
      >
        <span>{{ dialogTitle }}</span>
        <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text
        class="d-flex justify-center flex-column"
        style="font-family:'Poppins-Medium';color: #1e1e1e"
      >
        <v-form ref="form" v-if="action !== 'delete'">
          <div style="font-family:'Poppins-Medium'; color: #1e1e1e">
            Title
          </div>
          <v-text-field
            v-model="title"
            label="Title"
            type="text"
            required
            solo
            flat
            outlined
            class="mt-2 app-regular-font"
            dense
          ></v-text-field>
          <div style="font-family:'Poppins-Medium'; color: #1e1e1e">
            Score From
          </div>
          <v-text-field
            v-model="score_from"
            label="Score From"
            type="number"
            required
            solo
            flat
            outlined
            class="mt-2 app-regular-font"
            dense
          ></v-text-field>
          <div style="font-family:'Poppins-Medium'; color: #1e1e1e">
            Score To
          </div>
          <v-text-field
            v-model="score_to"
            label="Score To"
            type="number"
            required
            solo
            flat
            outlined
            class="mt-2 app-regular-font"
            dense
          ></v-text-field>
          <div style="font-family:'Poppins-Medium'; color: #1e1e1e">
            Select Color
          </div>
          <v-color-picker
            v-model="selectedColor"
            label="Select Color"
            hide-canvas
            mode="hexa"
          ></v-color-picker>
        </v-form>
        <v-card-text v-if="action === 'delete'">
          Are you sure you want to delete the colour <b>{{ title }}</b>?
        </v-card-text>
      </v-card-text>
      <v-card-text v-if="message">
        <v-alert dense type="error">{{ message }}</v-alert>
      </v-card-text>
      <v-card-actions
        style="display: flex; flex-direction: row; justify-content:center;"
        class="pb-6 mt-3"
      >
        <v-btn color="#e84023" @click="onClose" class="white--text" width="150">
          Close
        </v-btn>
        <v-btn color="#3B7CA1FF" @click="handleAction" class="white--text" width="150">
          {{ actionButtonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    action: {
      type: String,
      required: true
    },
    colorData: {
      type: Object,
      default: () => ({})
    },
    onClose: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      message: null,
      loading: false,
      title: this.colorData?.title || "",
      score_from: this.colorData?.score_from || "",
      score_to: this.colorData?.score_to || "",
      selectedColor: this.colorData?.colour_code || ""
    };
  },
  computed: {
    dialogTitle() {
      return this.action === "create" ? "Add scores and Colour" : this.action === "edit" ? "Edit scores and Colour" : "Delete Colour";
    },
    actionButtonText() {
      return this.action === "create" ? "Create" : this.action === "edit" ? "Save" : "Delete";
    }
  },
  methods: {
    ...mapActions({
      createColor: "colour/createColor",
      updateColor: "colour/editColor",
      deleteColor: "colour/deleteColor"
    }),
    handleAction() {
      if (this.action === "create") {
        this.createColour();
      } else if (this.action === "edit") {
        this.editColour();
      } else if (this.action === "delete") {
        this.deleteColour();
      }
    },
    async createColour() {
      this.loading = true;
      const params = {
        title: this.title,
        score_from: this.score_from,
        score_to: this.score_to,
        colour_code: this.selectedColor
      };
      try {
        await this.createColor(params);
        this.message = null;
        this.$emit("action-complete", { action: "create", data: params });
        this.onClose();
      } catch (error) {
        this.message = error.response?.data?.message || "Error creating colour";
      } finally {
        this.loading = false;
      }
    },
    async editColour() {
      this.loading = true;
      const params = {
        _id: this.colorData?._id,
        title: this.title,
        score_from: this.score_from,
        score_to: this.score_to,
        colour_code: this.selectedColor
      };
      try {
        await this.updateColor(params);
        this.message = null;
        this.$emit("action-complete", { action: "edit", data: params });
        this.onClose();
      } catch (error) {
        this.message = error.response?.data?.message || "Error editing colour";
      } finally {
        this.loading = false;
      }
    },
    async deleteColour() {
      this.loading = true;
      try {
        await this.deleteColor({ _id: this.colorData?._id });
        this.message = null;
        this.$emit("action-complete", { action: "delete", data: this.colorData });
        this.onClose();
      } catch (error) {
        this.message = error.response?.data?.message || "Error deleting colour";
      } finally {
        this.loading = false;
      }
    }
  },
  watch: {
    colorData(newValue) {
      this.title = newValue?.title || "";
      this.score_from = newValue?.score_from || "";
      this.score_to = newValue?.score_to || "";
      this.selectedColor = newValue?.colour_code || "";
    },
    dialog(newValue) {
      if (newValue && this.action === "edit") {
        this.selectedColor = this.colorData?.colour_code || "";
      }
    }
  }
};
</script>

<style scoped>
/* Add your styles here */
</style>