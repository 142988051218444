<template>
  <keep-alive>
    <v-container fluid class="d-flex flex-column h-100 pa-0 min-height-inherit">
      <div class="d-flex flex-row h-100 pa-0 min-height-inherit">
        <admin-left-section class="hidden-md-and-down" />
        <admin-colour-list/>
      </div>
      <v-snackbar v-model="snackbar">
        {{ errorMessage }}
      </v-snackbar>
    </v-container>
  </keep-alive>
</template>
<script>
import AdminLeftSection from "../home/AdminHomeSideMenu";
import AdminColourList from './AdminColourList.vue';
export default {
  components: {
    AdminLeftSection,
    AdminColourList,
  },
  data() {
    return {
      active: false,
      snackbar: false,
      errorMessage: '',
    };
  },
  methods: {

  }
};
</script>
<style scoped>
.card_home {
  min-height: 100px;
  background-image: linear-gradient(#efecf4, #f6f4f9, #fbfafc);
  bottom: 0;
  width: 100%;
  /* height: 100%; */
  margin: 0px;
  padding: 0px;
}
.item_title {
  color: #472583;
  font-family: "Poppins-Medium";
  font-size: 20px;
  margin-left: 5px;
}
.item_count {
  color: white;
  background-color: #ea1818;
  font-family: "Poppins-Medium";
  font-size: 20px;
}
</style>
