<template>
  <keep-alive>
    <v-container
      fluid
      style="height: 100%; padding:0px; min-height: inherit; max-width: 1500px"
      class="d-flex flex-column"
    >
      <v-alert
        dismissible
        v-for="notification in notifications"
        :key="notification"
        style="margin-left: 100px; margin-right: 80px;"
        type="success"
      >
        {{ notification }}
      </v-alert>
      <v-alert
        dismissible
        v-for="notification in errorNotifications"
        :key="notification"
        style="margin-left: 100px; margin-right: 80px;"
        type="error"
      >
        {{ notification }}
      </v-alert>
      <div
        style="height: 100%; padding:0px; min-height: inherit; max-width: 1500px"
        class="flex-row d-flex"
      >
        <volunteer-left-section class="hidden-sm-and-down" v-if="profile.type === 'Volunteer'"/>
        <company-left-section class="hidden-sm-and-down" v-if="profile.type == 'Company'" />
        <agency-left-section class="hidden-sm-and-down" v-if="profile.type === 'Agency'"/>
         <div  
        class="comp-cards">
        <members-register v-if="profile.type === 'Volunteer' || 'Company' && routername === 'join-as-member'"/>
        <members-list-view v-if="profile.type === 'Agency'"/>
        <router-view v-if="routername ==='members-history'"/>
      </div>
      </div>
      <v-snackbar v-model="snackbar">
        {{ errorMessage }}
      </v-snackbar>
    </v-container>
  </keep-alive>
</template>
<script>
import { mapActions, mapState } from "vuex";
import VolunteerLeftSection from "../volunteer/VolunteerLeftSection.vue";
import AgencyLeftSection from "../agency/AgencyLeftSection.vue";  
import MembersRegister from './MembersRegister.vue';
import MembersListView from './MembersListview.vue';
import CompanyLeftSection from '../company/CompanyLeftSection.vue';
export default {
  components: {
    VolunteerLeftSection,
    MembersRegister,
    AgencyLeftSection,
    MembersListView,
    CompanyLeftSection
  },
  data() {
    return {
      loading: false,
      errorMessage: null,
      snackbar: false,
      verifyPhoneDialog: false,
      completeProfileDialog: false,
      causeDialog: false,
      interestsDialog: false,
      availabilitiesDialog: false,
      congratesDialog: false,
      notifications: [],
      errorNotifications: []
    };
  },
  methods: {
    ...mapActions("event", {
      fetchAllEvents: "fetchAllEvents"
    }),
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    }
  },
  computed: {
    ...mapState("auth", {
      profile: "profile",
    }),
    routername() {
      const routeName = this.$route.name; 
      return routeName; 
    },
  },
};
</script>
<style scoped>
    .comp-cards {
    width: 100%;
    height: auto;
    }
</style>