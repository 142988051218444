<template>
    <div>
        <h1 class="detail-title white--text font-30 app-medium-font">
            Detailed View
        </h1>
        <v-tabs>
            <v-tab>
            Participated Team Challenges
            </v-tab>
            <v-tab>
            Invited Team Challenges
            </v-tab>

            <v-tab-item>
            <v-card>
                <v-card-text>
                     <div v-if="!participatedCompetitions.length" class="text-bold font-weight-bold">
                        {{errorMessage}}
              <v-skeleton-loader
                type="table"
                class="elevation-1"
                :loading="!participatedCompetitions.length"
                height="200px"
              ></v-skeleton-loader>
            </div>
             <div v-else>
                <v-data-table
                    :headers="participated_headers"
                    :items="participatedCompetitions"
                    class="elevation-1"
                >
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title>Team Challenges Participated</v-toolbar-title>
                            <v-divider
                                class="mx-4"
                                inset
                                vertical
                            ></v-divider>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                    </template>
                </v-data-table>
             </div>
                </v-card-text>
            </v-card>
            </v-tab-item>

            <v-tab-item>
            <v-card>
                <v-card-text>
                    <div v-if="!invitedCompetitions.length" class="text-bold font-weight-bold">
                        {{errorMessage}}
              <v-skeleton-loader
                type="table"
                class="elevation-1"
                :loading="!competitions.length"
                height="200px"
              ></v-skeleton-loader>
                    </div>
                     <div v-else>
                  <v-data-table
                    :headers="invited_headers"
                    :items="invitedCompetitions"
                    class="elevation-1"
                >
                    <template v-slot:top >
                        <v-toolbar flat >
                            <v-toolbar-title >Team Challenges Invitations</v-toolbar-title>
                            <v-divider
                                class="mx-4"
                                inset
                                vertical
                            ></v-divider>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                    </template>
                    <template v-slot:item.action="{ item }">
                         <v-tooltip bottom >
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                style="
                                border-radius: 1px;
                                border-color: #0a5b8a;
                                border-style: solid;
                                border-radius: 36px;
                                margin-right: 10px;
                                "
                                small
                                @click.stop="approveCompetition(item)"
                                v-bind="attrs"
                                v-on="on"
                                :disabled="item.response_status === 'Accepted'"
                                :loading="confirmingResponse == item._id"
                            >
                                <v-icon color="#0A5B8A" style="margin-top: 2px" size="18" 
                                >mdi-check</v-icon
                                >
                            </v-btn>
                            </template>
                            Confirm
                        </v-tooltip>
                         <v-tooltip bottom v-if="(item.response_status == 'Pending' || item.response_status == 'Accepted')">
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                style="
                                border-radius: 1px;
                                border-color: #0a5b8a;
                                border-style: solid;
                                border-radius: 36px;
                                margin-right: 10px;
                                "
                                small
                                @click.stop="rejectCompetition(item)"
                                v-bind="attrs"
                                v-on="on"
                                :disabled="item.response_status == 'Accepted'"
                                :loading="rejectResponse == item._id"
                            >
                                <v-icon color="#0A5B8A" style="margin-top: 2px" size="18" 
                                >mdi-close</v-icon
                                >
                            </v-btn>
                            </template>
                            Reject
                        </v-tooltip>
                    </template>
                </v-data-table>
                 </div>
                </v-card-text>
            </v-card>
            </v-tab-item>
        </v-tabs>
        <v-snackbar v-model="snackbar">
            {{ errorMessage }}
        </v-snackbar>
    </div>
</template>

<script>
import { mapActions,mapState } from "vuex";
export default {
    data() {
        return {
            search: '',
            errorMessage: null,
            confirmingResponse:'',
            rejectResponse:'',
            snackbar: false,
            participated_headers: [
                { text: 'Title', value: 'competitionId.title' },
                { text: 'From', value: 'createdBy.name' },
                { text: 'Status', value: 'response_status' },
                // { text: 'Competition Points', value: 'competition_points' }
            ],
            invited_headers: [
                { text: 'Title', value: 'competitionId.title' },
                { text: 'From', value: 'createdBy.name' },
                { text: 'Status', value: 'response_status' },
                // { text: 'Competition Points', value: 'competition_points' }
                { text: 'Actions', value: 'action', sortable: false }
            ],
            competitions: [],
            invitedCompetitions: [],
            participatedCompetitions: [],
        };
    },
    methods: {
         ...mapActions({
                fetchEachCompetitions: "competition/fetchEachCompetitions",
                getCompetitionTeamInvitation : "competition/getCompetitionTeamInvitation",
                respondToCompetitionRequest: "competition/respondToCompetitionRequest",
                getParticipatedCompetitionTeam : "competition/getParticipatedCompetitionTeam"
            }),
        async approveCompetition(item) {
           const params = {
            competition_id: item.competitionId._id,
            response_status: 'Accepted'
            };
            try {
            this.confirmingResponse = item._id;
            await this.respondToCompetitionRequest(params);
            item.response_status = 'Accepted';
            } catch (error) {
            this.showErrorMessage(
            error || "An error occurred while responding to competition request."
            );
            } finally {
            this.confirmingResponse = '';
           }
        },
        async rejectCompetition(item) {
               const params = {
                competition_id: item.competitionId._id,
                response_status: 'Rejected'
            };
            try {
                this.rejectResponse = item._id;
                await this.respondToCompetitionRequest(params);
                   item.response_status = 'Accepted';
            } catch (error) {
                this.snackbar = true;
                this.showErrorMessage(
                error || "An error occurred while responding to competition request."
                );
            } finally {
                this.rejectResponse = '';
           }
        },
           showErrorMessage(message) {
                this.errorMessage = message;
                this.snackbar = true;
            },
        async loadCompetitions() {
            try {
                this.loading = true;
                const response = await this.fetchEachCompetitions();
                this.competitions = response;
            } catch (error) {
                this.snackbar = true;
                this.showErrorMessage(
                error.message || "An error occurred while loading competitions."
                );
            } finally {
                this.loading = false;
            }
        },
        async loadTeamInvitations() {
            try {
                const response = await this.getCompetitionTeamInvitation();
                this.invitedCompetitions = response;
            } catch (error) {
                this.snackbar = true;
                this.loading = false;
                this.showErrorMessage(
                error.message || "An error occurred while loading competitions."
                );
            } finally {
                this.loading = false;
            }
        },
        async fetchParticipatedCompetitionTeam (){
            try {
                this.loading = true;
                const response = await this.getParticipatedCompetitionTeam();
                this.participatedCompetitions = response;
            } catch (error) {
                this.snackbar = true;
                this.loading = false;
                this.showErrorMessage(
                error.message || "An error occurred while loading competitions."
                );
            } finally {
                this.loading = false;
            }
        }
    },
    computed: {
        ...mapState("auth", {
        profile: "profile",
        }),
    },
  mounted() {
    this.loadCompetitions();
    this.loadTeamInvitations();
    this.fetchParticipatedCompetitionTeam();
  },
}
</script>

<style scoped>
.detail-title{
    margin-top: 20px;
    margin-bottom: 20px;
}
</style>