import axiosInstance from "@/api/axios";

export default {
  namespaced: true,
  state: {
    colors: [],
    colours: []
  },
  getters: {
    getAllColors(state) {
      return state.colours;
    }
  },
  mutations: {
      setColours(state, colours) {
        state.colours = colours;
      },
      ADD_COLOR(state, color) {
        state.colors.push(color);
      },
      UPDATE_COLOR(state, updatedColor) {
        const index = state.colors.findIndex(color => color._id === updatedColor._id);
        if (index !== -1) {
          state.colors.splice(index, 1, updatedColor);
        }
      },
      REMOVE_COLOR(state, colorId) {
        state.colors = state.colors.filter(color => color._id !== colorId);
      }
  },
  actions: {
    createColor({ commit, rootState }, params) {
        return new Promise((resolve, reject) => {
          axiosInstance(rootState.auth.token)
            .post("scoreColours/createScoreColours", params, {
              headers: {
                Authorization: rootState.auth.token,
                "Content-Type": "application/json"
              }
            })
            .then(res => {
              if (res.data.success) {
                commit("ADD_COLOR", res.data.res);
                resolve(res.data.message);
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              console.error("API error:", error);
              reject(error.message || "An error occurred while creating the color.");
            });
        });
      },
    editColor({ commit, rootState }, params) {
        return new Promise((resolve, reject) => {
          axiosInstance(rootState.auth.token)
            .post("scoreColours/updateScoreColour", params, {
              headers: {
                Authorization: rootState.auth.token,
                "Content-Type": "application/json"
              }
            })
            .then(res => {
              if (res.data.success) {
                commit("UPDATE_COLOR", res.data.data);
                resolve(res.data.message);
              } else {
                reject(res.data.message || "An error occurred while editing the color.");
              }
            })
            .catch(error => {
              console.error("API error:", error);
              reject(error.message || "An error occurred while editing the color.");
            });
        });
      },
    getAllColor({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
        .get("scoreColours/getAllScoreColours", {
          headers: {
            Authorization: rootState.auth.token,
            "Content-Type": "application/json"
          }
        })
        .then(res => {
          if (res.data.success) {
            commit("setColours", res.data.data);
            resolve(res.data.data);
          } else {
            reject(res.data.data);
          }
        })
        .catch(error => {
          console.error("API error:", error);
          reject(error);
        });
      });
    },
    deleteColor({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("scoreColours/deactivateScoreColour", params, {
            headers: {
              Authorization: rootState.auth.token,
              "Content-Type": "application/json"
            }
          })
          .then(res => {
            if (res.data.success) {
              commit("REMOVE_COLOR", res.data.data);
              resolve(res.data.data);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            console.error("API error:", error);
            reject(error);
          });
      });
    }
  }
};
