<template>
  <div class="create-post">
    <div class="blog-container">
      <div class="blog-info">
        <input
          type="text"
          placeholder="Enter Blog Title"
          v-model="blog.title"
          style="flex-grow: 1"
        />
        <div class="upload-file">
          <label for="blog-photo">Upload Cover Photo</label>
          <input
            ref="blogPhoto"
            id="blog-photo"
            @change="fileChange"
            class="d-none"
            type="file"
            accept="image/*"
          />
        </div>
      </div>
      <div>
        <v-img :src="selectedImage" v-if="selectedImage" />
      </div>
      <div class="editor">
        <vue-editor
          :editorOptions="editorSettings"
          v-model="blog.html"
          useCustomImageHandler
          @image-added="imageHandler"
        />
      </div>
      <div class="blog-actions">
        <v-btn @click="uploadBlog" :loading="loading" rounded
          >Publish Blog</v-btn
        >
        <v-btn @click="onBlogPreview" rounded>Post Preview</v-btn>
        <v-btn @click="cancel" rounded>Cancel</v-btn>
      </div>
      <blog-preview
        :dialog="previewDialog"
        :onClose="onClosePreview"
        :blog="blog"
      />
      <v-snackbar v-model="error">
        {{ errorMsg }}
      </v-snackbar>
    </div>
  </div>
</template>
<script>
import Quill from "quill";
import { mapActions } from "vuex";
import BlogPreview from "./BlogPreview.vue";
window.Quill = Quill;
const ImageResize = require("quill-image-resize-module").default;
Quill.register("modules/imageResize", ImageResize);
const VideoResize = require("quill-video-resize-module").default;
Quill.register("modules/VideoResize", VideoResize);
export default {
  components: { BlogPreview },
  name: "CreatePost",
  data() {
    return {
      file: null,
      error: null,
      errorMsg: null,
      loading: null,
      blog: { title: "", html: "" },
      editorSettings: {
        modules: {
          imageResize: {},
          VideoResize: {}
        }
      },
      selectedImage: null,
      previewDialog: false
    };
  },
  methods: {
    ...mapActions("blog", {
      uploadBlogImage: "uploadBlogImage",
      createBlog: "createBlog"
    }),
    fileChange() {
      if (this.$refs.blogPhoto.files.length != 0) {
        this.file = this.$refs.blogPhoto.files[0];
        this.selectedImage = URL.createObjectURL(this.file);
        this.blog.cover = this.selectedImage;
      }
    },
    onBlogPreview() {
      this.previewDialog = true;
    },
    onClosePreview() {
      this.previewDialog = false;
    },
    imageHandler(file, Editor, cursorLocation, resetUploader) {
      var formData = new FormData();
      formData.append("file", file);
      this.uploadBlogImage(formData)
        .then(res => {
          Editor.insertEmbed(cursorLocation, "image", res);
          resetUploader();
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response.data.message);
          } else {
            console.log(error);
          }
        });
    },
    uploadBlog() {
      if (this.blog.title.length !== 0 && this.blog.html.length !== 0) {
        if (this.file) {
          var formData = new FormData();
          formData.append("html", this.blog.html);
          // formData.append("blogCoverPhotoName", this.blogCoverPhotoName);
          formData.append("title", this.blog.title);
          formData.append("cover", this.file);

          this.loading = true;
          this.createBlog(formData).then(res => {
            console.log(res);
            this.loading = false;
            this.$router.push({
              name: "blog-details",
              query: { _id: res._id }
            });
          });
        } else {
          this.error = true;
          this.errorMsg = "Please ensure you uploaded a cover photo!";
          setTimeout(() => {
            this.error = false;
          }, 5000);
        }
        return;
      }
      this.error = true;
      this.errorMsg = "Please ensure Blog Title & Blog Post has been filled!";
      setTimeout(() => {
        this.error = false;
      }, 5000);
      return;
    },
    cancel(){
      this.$router.push({ name: "blog-feeds" });
    }
  }
};
</script>

<style lang="scss">
.create-post {
  position: relative;
  height: 100%;
  button {
    margin-top: 0;
  }
  .router-button {
    text-decoration: none;
    color: #fff;
  }
  label {
    transition: 0.5s ease-in-out all;
    align-self: center;
    font-size: 14px;
    cursor: pointer;
    border-radius: 20px;
    padding: 12px 24px;
    color: #fff;
    background-color: #303030;
    text-decoration: none;
    &:hover {
      background-color: rgba(48, 48, 48, 0.7);
    }
  }
  .blog-container {
    position: relative;
    height: 100%;
    padding: 10px 25px 60px;
  }
  // error styling
  .invisible {
    opacity: 0 !important;
  }
  .err-message {
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    color: #fff;
    margin-bottom: 10px;
    background-color: #303030;
    opacity: 1;
    transition: 0.5s ease all;
    p {
      font-size: 14px;
    }
    span {
      font-weight: 600;
    }
  }
  .blog-info {
    display: flex;
    margin-bottom: 32px;
    input:nth-child(1) {
      min-width: 300px;
    }
    input {
      color: white;
      transition: 0.5s ease-in-out all;
      padding: 10px 4px;
      border: none;
      border-bottom: 1px solid #303030;
      &:focus {
        outline: none;
        box-shadow: 0 1px 0 0 #303030;
      }
    }
    input::placeholder {
      color: lightgray; /* Replace 'red' with your desired color */
    }

    /* For older versions of Firefox */
    input::-moz-placeholder {
      color: lightgray;
    }

    /* For older versions of WebKit browsers (e.g., Safari) */
    input::-webkit-input-placeholder {
      color: lightgray;
    }
    .upload-file {
      margin-left: 16px;
      position: relative;
      display: flex;
      input {
        display: none;
      }
      .preview {
        margin-left: 16px;
        text-transform: initial;
      }
      span {
        font-size: 12px;
        margin-left: 16px;
        align-self: center;
      }
    }
  }
  .editor {
    height: 60vh;
    display: flex;
    flex-direction: column;
    background-color: white;
    .quillWrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    .ql-container {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: scroll;
    }
    .ql-editor {
      padding: 20px 16px 30px;
    }
    iframe {
      pointer-events: none;
    }
  }
  .blog-actions {
    margin-top: 32px;
    button {
      margin-right: 16px;
    }
  }
}
// @media (min-width: 960px) {
//   .create-post {
//     width: calc(100% - 425px);
//   }
// }
// @media (max-width: 961px) {
.create-post {
  width: 100%;
}
// }
</style>
