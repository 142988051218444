<template>
  <v-dialog 
    v-model="localDialog" 
    max-width="1200px" 
    @update:model-value="handleClose"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Need Details</span>
      </v-card-title>
      <v-card-text>
        <p>Skipped : {{ summaryDetails.notParticipated }}</p>
        <p>Participated : {{ summaryDetails.participated }}</p>
        <p>Responded : {{ summaryDetails.responded }}</p>
        <p>Total Worked Needs : {{ summaryDetails.totalNeeds }}</p>
      </v-card-text>
      <v-card-text>
        <v-data-table :headers="headers" :items="needDetails" class="elevation-1">
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Summary</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:item.volunteerId="{ item }">
            <span>{{ item.volunteerId }}</span>
          </template>
          <template v-slot:item.details="{ item }">
            <span>{{ item.details }}</span>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="handleClose">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    volunteer: Object,
    onClose: {
      type: Function
    },
    needDetails: {
      type: Array,
      default: () => []
    },
    summaryDetails: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      headers: [
        { text: "Title", value: "title" },
        { text: "From Time", value: "fromTime" },
        { text: "To Time", value: "toTime" },
        { text: "Date", value: "participationDate" },
        { text: "Total Hours Spent", value: "hoursWorked" }
      ]
    };
  },
  computed: {
    // Dynamically sync the dialog state with the prop
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("update:dialog", value); // Notify the parent component about the state change
        if (!value && this.onClose) {
          this.onClose(); // Call the `onClose` method if provided when closing the dialog
        }
      }
    }
  },
  methods: {
    ...mapActions("volunteer", {
      updateVolunteer: "editVolunteer",
      VolunteerNeedView: "VolunteerDetailedNeedView"
    }),
    handleClose() {
      this.localDialog = false; // Trigger the setter of localDialog
    }
  }
};
</script>


<style scoped>
.v-dialog::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.v-dialog::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.v-dialog::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
.v-dialog::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
</style>
