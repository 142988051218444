<template>
  <v-container
    fluid
    style="min-height: inherit"
    class="d-flex flex-row pa-0 h-100"
    :class="{ 'max-width-1500': type != 'Admin' }"
  >
    <agency-left-section class="hidden-sm-and-down" v-if="type == 'Agency'" />
    <company-left-section class="hidden-sm-and-down" v-if="type == 'Company'" />
    <volunteer-left-section
      class="hidden-sm-and-down"
      v-else-if="type == 'Volunteer'"
    />
    <admin-home-side-menu v-else-if="type == 'Admin'" />
    <router-view />
    <agency-right-section class="hidden-md-and-down" v-if="type == 'Agency'" />
    <volunteer-right-section
      class="hidden-md-and-down"
      v-else-if="type == 'Volunteer'"
    />
    <admin-right-section
      class="hidden-sm-and-down"
      v-else-if="type == 'Admin'"
    />
      <volunteer-right-section
          class="hidden-md-and-down"
          v-else-if="type == 'Company'"
        />
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
  </v-container>
</template>
<script>
import { mapActions, mapState } from "vuex";
import AdminRightSection from "../../admin/AdminRightSection.vue";
import VolunteerLeftSection from "../../volunteer/VolunteerLeftSection.vue";
import VolunteerRightSection from "../../volunteer/VolunteerRightSection.vue";
import AgencyLeftSection from "../AgencyLeftSection.vue";
import AgencyRightSection from "../AgencyRightSection.vue";
import AdminHomeSideMenu from "../../admin/home/AdminHomeSideMenu.vue";
import CompanyLeftSection from "../../company/CompanyLeftSection.vue";
export default {
  components: {
    AgencyLeftSection,
    AgencyRightSection,
    VolunteerLeftSection,
    VolunteerRightSection,
    AdminRightSection,
    AdminHomeSideMenu,
    CompanyLeftSection
  },
  data() {
    return {
      loading: false,
      errorMessage: null,
      snackbar: false
    };
  },
  methods: {
    ...mapActions("event", {
      fetchAllEvents: "fetchAllEvents"
    }),
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    }
  },
  computed: {
    ...mapState("auth", {
      type: "type"
    })
  }
};
</script>
<style scoped></style>
