import ApiManager from "@/api/ApiManager";
import axiosInstance from "@/api/axios";

export default {
  namespaced: true,
  state: {
    token: localStorage.getItem("user-token") || "",
    profile: {},
    type: localStorage.getItem("user-type") || "",
    sideSection: { agency: 0, volunteer: 0, blog: 0, need: 0, location: 0 },
    weather: null
  },
  getters: {
    getToken(state) {
      return state.token;
    },
    isAuthenticated: state => !!state.token,

    getProfile(state) {
      return state.profile;
    },
    getType(state) {
      return state.type;
    }
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setType(state, type) {
      state.type = type;
    },
    setProfile(state, data) {
      state.profile = data;
    },
    updateProfile(state, data) {
      state.profile = { ...state.profile, ...data };
    },
    updateAdmin(state, data) {
      state.admin = data;
    },
    updateSectionNumbers(state, data) {
      if (data.agency) state.sideSection.agency = data.agency;
      if (data.volunteer) state.sideSection.volunteer = data.volunteer;
      if (data.blog) state.sideSection.blog = data.blog;
      if (data.need) state.sideSection.need = data.need;
      if (data.location) state.sideSection.location = data.location;
    },
    setWeather(state, data) {
      state.weather = data;
    },
    setUnit(state, data) {
      state.unit = data;
    },
    verifyPhone(state) {
      state.profile.phone_verified = true;
    }
  },
  actions: {
    onLogin({ commit }, params) {
      return new Promise((resolve, reject) => {
        ApiManager.onLogin(
          params,
          (token, type) => {
            commit("setToken", token);
            commit("setType", type);
            localStorage.setItem("user-token", token);
            localStorage.setItem("user-type", type);
            resolve(type);
          },
          error => {
            reject(error);
          }
        );
      });
    },
    onRegisterVolunteer({ commit }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance()
          .post("auth/user-register", params)
          .then(res => {
            if (res.data.success == true) {
              commit("setToken", res.data.token);
              commit("setType", res.data.type);
              localStorage.setItem("user-token", res.data.token);
              localStorage.setItem("user-type", res.data.type);
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    onRegisterAgency({ commit }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance()
          .post("auth/agency-register", params)
          .then(res => {
            if (res.data.success == true) {
              commit("setToken", res.data.token);
              commit("setType", res.data.type);
              localStorage.setItem("user-token", res.data.token);
              localStorage.setItem("user-type", res.data.type);
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    onRegisterBusiness({ commit }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance()
          .post("auth/createCompanyRegister", params)
          .then(res => {
            if (res.data.success == true) {
              commit("setToken", res.data.token);
              commit("setType", res.data.type);
              localStorage.setItem("user-token", res.data.token);
              localStorage.setItem("user-type", res.data.type);
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    onLogout({ commit }) {
      commit("setToken", null);
      commit("setProfile", {});
      localStorage.removeItem("user-token");
    },
    deleteAccount({ commit, state }) {
      if (state.type == "Admin") {
        return new Promise((resolve, reject) => {
          axiosInstance(state.token)
            .post("admin/delete")
            .then(res => {
              if (res.data.success == true) {
                commit("setToken", null);
                commit("setProfile", {});
                localStorage.removeItem("user-token");
                resolve();
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      } else if (state.type == "Agency") {
        return new Promise((resolve, reject) => {
          axiosInstance(state.token)
            .post("agency/delete")
            .then(res => {
              if (res.data.success == true) {
                commit("setToken", null);
                commit("setProfile", {});
                localStorage.removeItem("user-token");
                resolve();
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      } else if (state.type == "Volunteer") {
        return new Promise((resolve, reject) => {
          axiosInstance(state.token)
            .post("users/delete")
            .then(res => {
              if (res.data.success == true) {
                commit("setToken", null);
                commit("setProfile", {});
                localStorage.removeItem("user-token");
                resolve();
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      }
    },
    onForgotPassword({ state }, params) {
      console.log(state.token);
      return new Promise((resolve, reject) => {
        axiosInstance()
          .post("auth/forgot-password", params)
          .then(res => {
            if (res.data.success == true) {
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    onSubmitVerificationCode({ state }, params) {
      console.log(state.token);
      return new Promise((resolve, reject) => {
        axiosInstance()
          .post("auth/verify-otp", params)
          .then(res => {
            if (res.data.success == true) {
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    onResetPassword({ state }, params) {
      console.log(state.token);
      return new Promise((resolve, reject) => {
        axiosInstance()
          .post("auth/reset-password", params)
          .then(res => {
            if (res.data.success == true) {
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    fetchProfile({ commit, state, rootState }) {
      if (state.type == "Admin") {
        return new Promise((resolve, reject) => {
          ApiManager.fetchAdmin(
            {
              Authorization: state.token
            },
            res => {
              commit("setProfile", res);
              resolve();
            },
            error => {
              if (error.response.data.message == "Admin not found") {
                commit("setToken", null);
                commit("setProfile", {});
                localStorage.removeItem("user-token");
              }
              reject(error.response.data.message);
            }
          );
        });
      } else if (state.type == "Agency") {
        return new Promise((resolve, reject) => {
          ApiManager.getAgency(
            {
              Authorization: state.token
            },
            null,
            res => {
              commit("setProfile", res);
              resolve();
            },
            error => {
              if (error.response.data.message == "Agency not found") {
                commit("setToken", null);
                commit("setProfile", {});
                localStorage.removeItem("user-token");
              }
              reject(error.response.data.message);
            }
          );
        });
      } else if (state.type == "Volunteer") {
        return new Promise((resolve, reject) => {
          axiosInstance(rootState.auth.token)
            .post("users/profile")
            .then(res => {
              if (res.data.success == true) {
                commit("setProfile", res.data.res);
                resolve();
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              if (
                error.response &&
                (error.response.data.message == "User not found" ||
                  error.response.data.message == "Permission denied")
              ) {
                commit("setToken", null);
                commit("setProfile", {});
                localStorage.removeItem("user-token");
              } else {
                console.log(
                  error.response ? error.response.data.message : error
                );
              }

              reject(error.response ? error.response.data.message : error);
            });
        });
      } else if (state.type == "Company") {
        return new Promise((resolve, reject) => {
          axiosInstance(rootState.auth.token)
            .post("company/profile")
            .then(res => {
              
              if (res.data.success == true) {
                console.log(res.data.res,'res');
                commit("setProfile", res.data.res);
                resolve();
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              if (
                error.response &&
                (error.response.data.message == "User not found" ||
                  error.response.data.message == "Permission denied")
              ) {
                commit("setToken", null);
                commit("setProfile", {});
                localStorage.removeItem("user-token");
              } else {
                console.log(
                  error.response ? error.response.data.message : error
                );
              }

              reject(error.response ? error.response.data.message : error);
            });
        });
      } else {
        commit("setToken", null);
        commit("setProfile", {});
        localStorage.removeItem("user-token");
      }
    },
    getHomeInfo({ state }) {
      if (state.type == "Admin") {
        return new Promise((resolve, reject) => {
          axiosInstance(state.token)
            .post("admin/home")
            .then(res => {
              if (res.data.success == true) {
                resolve(res.data.res);
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              reject(error.response.data.message);
            });
        });
      }
    },
    updateAdmin({ commit, state }, params) {
      return new Promise((resolve, reject) => {
        ApiManager.updateAdmin(
          {
            Authorization: state.token
          },
          params,
          res => {
            commit("setProfile", res);
            resolve();
          },
          error => {
            reject(error);
          }
        );
      });
    },
    sendVerificationCode({ state }) {
      if (state.type == "Volunteer") {
        return new Promise((resolve, reject) => {
          axiosInstance(state.token)
            .post("users/send-verification-code")
            .then(res => {
              if (res.data.success == true) {
                resolve();
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      } else if (state.type == "Agency") {
        return new Promise((resolve, reject) => {
          axiosInstance(state.token)
            .post("agency/send-verification-code")
            .then(res => {
              if (res.data.success == true) {
                resolve();
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      } 
      else if (state.type == "Company") {
        return new Promise((resolve, reject) => {
          axiosInstance(state.token)
            .post("agency/send-verification-code")
            .then(res => {
              if (res.data.success == true) {
                resolve();
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      } else if (state.type == "Admin") {
        return new Promise((resolve, reject) => {
          axiosInstance(state.token)
            .post("admin/send-verification-code")
            .then(res => {
              if (res.data.success == true) {
                resolve();
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      }
    },
    sendVoiceCode({ state }) {
      if (state.type == "Volunteer") {
        return new Promise((resolve, reject) => {
          axiosInstance(state.token)
            .post("users/send-voice-code")
            .then(res => {
              if (res.data.success == true) {
                resolve();
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      } else if (state.type == "Agency") {
        return new Promise((resolve, reject) => {
          axiosInstance(state.token)
            .post("agency/send-voice-code")
            .then(res => {
              if (res.data.success == true) {
                resolve();
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      } else if (state.type == "Admin") {
        return new Promise((resolve, reject) => {
          axiosInstance(state.token)
            .post("admin/send-voice-code")
            .then(res => {
              if (res.data.success == true) {
                resolve();
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      }
    },
    verifyPhone({ commit, state }, params) {
      if (state.type == "Volunteer") {
        return new Promise((resolve, reject) => {
          axiosInstance(state.token)
            .post("users/verify-phone", params)
            .then(res => {
              if (res.data.success == true) {
                commit("verifyPhone");
                resolve();
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      } else if (state.type == "Agency") {
        return new Promise((resolve, reject) => {
          axiosInstance(state.token)
            .post("agency/verify-phone", params)
            .then(res => {
              if (res.data.success == true) {
                commit("verifyPhone");
                resolve();
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      }
    },
    completeProfile({ commit, state }, params) {
      if (state.type == "Volunteer") {
        return new Promise((resolve, reject) => {
          axiosInstance(state.token)
            .post("users/complete", params)
            .then(res => {
              if (res.data.success == true) {
                commit("setProfile", res.data.res);
                resolve();
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      } else if (state.type == "Agency") {
        return new Promise((resolve, reject) => {
          axiosInstance(state.token)
            .post("agency/complete", params)
            .then(res => {
              if (res.data.success == true) {
                commit("setProfile", res.data.res);
                resolve();
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      }
    },
    updateProfile({ commit, state }, params) {
      if (state.type == "Volunteer") {
        return new Promise((resolve, reject) => {
          axiosInstance(state.token)
            .post("users/update", params)
            .then(res => {
              if (res.data.success == true) {
                commit("setProfile", res.data.res);
                resolve();
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      } else if (state.type == "Agency") {
        return new Promise((resolve, reject) => {
          axiosInstance(state.token)
            .post("agency/update", params)
            .then(res => {
              if (res.data.success == true) {
                commit("setProfile", res.data.res);
                resolve();
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      } else if (state.type == "Company") {
        return new Promise((resolve, reject) => {
          axiosInstance(state.token)
            .post("company/updateCompany", params)
            .then(res => {
              if (res.data.success == true) {
                commit("setProfile", res.data.res);
                resolve();
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      } 
      else if (state.type == "Admin") {
        return new Promise((resolve, reject) => {
          axiosInstance(state.token)
            .post("admin/update", params)
            .then(res => {
              if (res.data.success == true) {
                commit("setProfile", res.data.res);
                resolve();
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      }
    },
    updateBackground({ commit, state }, params) {
      if (state.type == "Volunteer") {
        return new Promise((resolve, reject) => {
          axiosInstance(state.token)
            .post("users/update-bg", params)
            .then(res => {
              if (res.data.success == true) {
                commit("setProfile", res.data.res);
                resolve();
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      } else if (state.type == "Agency") {
        return new Promise((resolve, reject) => {
          axiosInstance(state.token)
            .post("agency/update-bg", params)
            .then(res => {
              if (res.data.success == true) {
                commit("setProfile", res.data.res);
                resolve();
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      } else if (state.type == "Company") {
        return new Promise((resolve, reject) => {
          axiosInstance(state.token)
            .post("company/updateCompanyBackground", params)
            .then(res => {
              if (res.data.success == true) {
                commit("setProfile", res.data.company);
                resolve();
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      }
    },
    connectFacebook({ commit, state }, params) {
      if (state.type == "Volunteer") {
        return new Promise((resolve, reject) => {
          axiosInstance(state.token)
            .post("users/facebook-auth", params)
            .then(res => {
              if (res && res.data.success == true) {
                commit("setProfile", res.data.res);
                resolve();
              } else {
                reject(res ? res.data.message : "Unexpected issue");
              }
            })
            .catch(error => {
              console.log(error);
              reject(error);
            });
        });
      } else if (state.type == "Agency") {
        return new Promise((resolve, reject) => {
          axiosInstance(state.token)
            .post("agency/facebook-auth", params)
            .then(res => {
              if (res.data.success == true) {
                commit("setProfile", res.data.res);
                resolve();
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      }
    },
    connectInstagram({ commit, state }, params) {
      if (state.type == "Volunteer") {
        return new Promise((resolve, reject) => {
          axiosInstance(state.token)
            .post("users/instagram-auth", params)
            .then(res => {
              if (res.data.success == true) {
                commit("setProfile", res.data.res);
                resolve();
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      } else if (state.type == "Agency") {
        return new Promise((resolve, reject) => {
          axiosInstance(state.token)
            .post("agency/instagram-auth", params)
            .then(res => {
              if (res.data.success == true) {
                commit("setProfile", res.data.res);
                resolve();
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      }
    },
    connectLinkedIn({ commit, state }, params) {
      if (state.type == "Volunteer") {
        return new Promise((resolve, reject) => {
          axiosInstance(state.token)
            .post("users/linkedin-auth", params)
            .then(res => {
              if (res.data.success == true) {
                commit("setProfile", res.data.res);
                resolve();
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      } else if (state.type == "Agency") {
        return new Promise((resolve, reject) => {
          axiosInstance(state.token)
            .post("agency/linkedin-auth", params)
            .then(res => {
              if (res.data.success == true) {
                commit("setProfile", res.data.res);
                resolve();
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      }
    },
    requestTwitterToken({ state }, params) {
      if (state.type == "Volunteer") {
        return new Promise((resolve, reject) => {
          axiosInstance(state.token)
            .post("users/twitter-request-token", params)
            .then(res => {
              if (res.data.success == true) {
                resolve(res.data.res);
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      } else if (state.type == "Agency") {
        return new Promise((resolve, reject) => {
          axiosInstance(state.token)
            .post("agency/twitter-request-token", params)
            .then(res => {
              if (res.data.success == true) {
                resolve(res.data.res);
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      }
    },
    connectTwitter({ commit, state }, params) {
      if (state.type == "Volunteer") {
        return new Promise((resolve, reject) => {
          axiosInstance(state.token)
            .post("users/twitter-auth", params)
            .then(res => {
              if (res.data.success == true) {
                commit("setProfile", res.data.res);
                resolve();
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      } else if (state.type == "Agency") {
        return new Promise((resolve, reject) => {
          axiosInstance(state.token)
            .post("agency/twitter-auth", params)
            .then(res => {
              if (res.data.success == true) {
                commit("setProfile", res.data.res);
                resolve();
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      }
    },
    disconnectSocial({ commit, state }, params) {
      if (state.type == "Volunteer") {
        return new Promise((resolve, reject) => {
          axiosInstance(state.token)
            .post("users/disconnect-social", params)
            .then(res => {
              if (res.data.success == true) {
                commit("setProfile", res.data.res);
                resolve();
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      } else if (state.type == "Agency") {
        return new Promise((resolve, reject) => {
          axiosInstance(state.token)
            .post("agency/disconnect-social", params)
            .then(res => {
              if (res.data.success == true) {
                commit("setProfile", res.data.res);
                resolve();
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      }
    },
    verifyEmail({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("auth/verify-email", params)
          .then(res => {
            if (res.data.success == true) {
              resolve(res.data.message);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    getLeftSectionInfo({ commit, state, rootState }) {
      if (state.type == "Volunteer") {
        return new Promise((resolve, reject) => {
          axiosInstance(rootState.auth.token)
            .post("users/left-section")
            .then(res => {
              if (res.data.success == true) {
                commit("updateSectionNumbers", res.data.res);
                resolve(res.data.res);
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      } else if (state.type == "Agency") {
        return new Promise((resolve, reject) => {
          axiosInstance(rootState.auth.token)
            .post("agency/left-section")
            .then(res => {
              if (res.data.success == true) {
                commit("updateSectionNumbers", res.data.res);
                resolve(res.data.res);
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      } 
      else if (state.type == "Company") {
        return new Promise((resolve, reject) => {
          axiosInstance(rootState.auth.token)
            .post("company/getCompanyLeftSectionInfo")
            .then(res => {
              if (res.data.success == true) {
                commit("updateSectionNumbers", res.data.res);
                resolve(res.data.res);
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      } else if (state.type == "Admin") {
        return new Promise((resolve, reject) => {
          axiosInstance(rootState.auth.token)
            .post("admin/left-section")
            .then(res => {
              if (res.data.success == true) {
                commit("updateSectionNumbers", res.data.res);
                resolve(res.data.res);
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      }
    },
    getAdmin({ rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("admin/get", param)
          .then(res => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    getRightSectionInfo({ commit, state, rootState }) {
      if (state.type == "Volunteer") {
        return new Promise((resolve, reject) => {
          axiosInstance(rootState.auth.token)
            .post("users/right-section")
            .then(res => {
              if (res.data.success == true) {
                commit("updateSectionNumbers", res.data.res);
                resolve(res.data.res);
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      } else if (state.type == "Agency") {
        return new Promise((resolve, reject) => {
          axiosInstance(rootState.auth.token)
            .post("agency/right-section")
            .then(res => {
              if (res.data.success == true) {
                commit("updateSectionNumbers", res.data.res);
                resolve(res.data.res);
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      }  else if (state.type == "Company") {
        return new Promise((resolve, reject) => {
          axiosInstance(rootState.auth.token)
            .post("company/getCompanyRightSectionInfo")
            .then(res => {
              if (res.data.success == true) {
                commit("updateSectionNumbers", res.data.res);
                resolve(res.data.res);
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      }  
      else if (state.type == "Admin") {
        return new Promise((resolve, reject) => {
          axiosInstance(rootState.auth.token)
            .post("admin/right-section")
            .then(res => {
              if (res.data.success == true) {
                commit("updateSectionNumbers", res.data.res);
                resolve(res.data.res);
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      }
    },
    setWeather({ commit }, params) {
      commit("setWeather", params);
    },
    setUnit({ commit }, params) {
      commit("setUnit", params);
    },
    sendEmailVerification({ state }, params) {
      if (state.type == "Agency") {
        return new Promise((resolve, reject) => {
          axiosInstance(state.token)
            .post("agency/send-email-verification", params)
            .then(res => {
              if (res.data.success == true) {
                resolve(res.data.res);
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      } else if (state.type == "Volunteer") {
        return new Promise((resolve, reject) => {
          axiosInstance(state.token)
            .post("users/send-email-verification", params)
            .then(res => {
              if (res.data.success == true) {
                resolve(res.data.res);
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      } else if (state.type == "Admin") {
        return new Promise((resolve, reject) => {
          axiosInstance(state.token)
            .post("admin/send-email-verification", params)
            .then(res => {
              if (res.data.success == true) {
                resolve(res.data.res);
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      }
    },
    sendAdminEmail({ state }, params) {
      if (state.type == "Admin") {
        return new Promise((resolve, reject) => {
          axiosInstance(state.token)
            .post("admin/send-email", params)
            .then(res => {
              if (res.data.success == true) {
                resolve(res.data.message);
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      }
    },
    sendAdminSMS({ state }, params) {
      if (state.type == "Admin") {
        return new Promise((resolve, reject) => {
          axiosInstance(state.token)
            .post("admin/send-sms", params)
            .then(res => {
              if (res.data.success == true) {
                resolve(res.data.message);
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      }
    },
    getTypeFromEmail({ state }, param) {
      if (state.type == "Admin") {
        return new Promise((resolve, reject) => {
          axiosInstance(state.token)
            .post("admin/email-type", { email: param })
            .then(res => {
              if (res.data.success == true) {
                resolve(res.data.res);
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      }
    },
    getTypeFromPhone({ state }, param) {
      if (state.type == "Admin") {
        return new Promise((resolve, reject) => {
          axiosInstance(state.token)
            .post("admin/phone-type", { phone: param })
            .then(res => {
              if (res.data.success == true) {
                resolve(res.data.res);
              } else {
                reject(res.data.message);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      }
    }
  }
};
