<template><div></div> </template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  methods: {
    ...mapActions({
      onLogout: "auth/onLogout",
      fetchProfile: "auth/fetchProfile",
      getToken: "chat/getToken"
    })
  },
  computed: {
    ...mapGetters("auth", {
      isAuthenticated: "isAuthenticated"
    }),
    ...mapState("auth", {
      profile: "profile",
      type: "type"
    })
  },
  created() {
    if (!this.isAuthenticated) {
      this.$router.push({ name: "login", params: this.$route.params });
    } else if (this.type) {
      if (this.type == "Admin")
        this.$router.push({ name: "admin-home", params: this.$route.params });
      else if (this.type == "Agency") {
        this.$router.push({ name: "agency-home", params: this.$route.params });
      } 
      else if (this.type == "Company") {
        this.$router.push({ name: "company-home", params: this.$route.params });
      } 
      else if (this.type == "Volunteer") {
        this.$router.push({
          name: "volunteer-home",
          params: this.$route.params
        });
      } else {
        this.$router.push({
          name: "volunteer-home",
          params: this.$route.params
        });
      }
    } else {
      this.onLogout();
    }
  }
};
</script>
