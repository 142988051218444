<template>
  <div class="overflow-hidden h-100 w-100">
    <v-container
      class="pb-5 page-content"
      fluid
      v-if="profileType == 'Admin' && agency"
    >
      <v-img
        :src="
          agency.bgImage ? agency.bgImage : require('@/assets/background0.png')
        "
        :width="
          $vuetify.breakpoint.smAndDown
            ? 'calc(100% - 28px)'
            : 'calc(100% - 328px)'
        "
        :aspect-ratio="$vuetify.breakpoint.smAndDown ? 414 / 180 : 1654 / 455"
        class="rounded-lg rounded-b-0 position-absolute"
      />
      <div
        class="d-flex"
        :style="
          $vuetify.breakpoint.mdAndUp
            ? 'margin-top: calc((100vw - 320px) * 455 / 1654 - 100px);'
            : 'margin-top: calc((100vw - 20px) * 455 / 1654 - 20px)'
        "
        :class="{ 'flex-column': $vuetify.breakpoint.smAndDown }"
      >
        <div
          :style="
            $vuetify.breakpoint.mdAndUp
              ? 'width: 260px; min-width: 260px; z-index: 1; margin-top: 60px;'
              : 'width: 100%; margin-top: 60px;z-index: 1;'
          "
          class="d-flex flex-column align-center bg-white rounded-bl-lg"
        >
          <agency-avatar
            :img="agency.image"
            :size="120"
            :name="agency.name"
            :editable="true"
            :onImageSelected="onImageSelected"
            style="margin-top: -60px;"
          />
          <div class="mt-5 app-bold-font font-20 mb-3">
            {{ agency.name }}
          </div>
          <div>
            {{ agency.email }}
          </div>
          <div>
            {{ agency.phone }}
          </div>
        </div>
        <div class="flex-grow">
          <v-card class="rounded-lg rounded-bl-0" flat>
            <v-tabs v-model="tab" align-with-title left>
              <v-tabs-slider color="yellow"></v-tabs-slider>
              <v-tab v-for="item in tabMenus" :key="item">
                {{ item }}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item v-for="item in tabMenus" :key="item">
                <v-card flat v-if="item == 'Basic Information'">
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          hide-details
                          label="Agency Name"
                          dense
                          filled
                          outlined
                          v-model="agency.name"
                          background-color="white"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          hide-details
                          label="Customize link"
                          dense
                          outlined
                          filled
                          v-model="agency.link"
                          background-color="white"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          hide-details
                          label="Hours of Operation"
                          dense
                          outlined
                          filled
                          rows="3"
                          auto-grow
                          v-model="agency.hours_of_operation"
                          background-color="white"
                          :rules="[rules.required]"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="agency.causes"
                          :items="causes"
                          item-text="name"
                          item-label="name"
                          label="Causes"
                          multiple
                          clearable
                          auto-select-first
                          deletable-chips
                          chips
                          outlined
                          background-color="white"
                          hide-details
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          hide-details
                          label="Agency Video"
                          dense
                          outlined
                          filled
                          v-model="agency.video"
                          background-color="white"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          hide-details
                          label="EIN"
                          dense
                          outlined
                          filled
                          v-model="agency.ein"
                          background-color="white"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        style="display:flex; justify-content: flex-end"
                      >
                        <v-btn
                          color="#0A5B8A"
                          class="mt-5"
                          dark
                          @click="updateAgency"
                        >
                          Update Information
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-card flat v-else-if="item == 'Location'">
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          hide-details
                          label="Address"
                          type="address"
                          dense
                          outlined
                          filled
                          background-color="white"
                          v-model="agency.address"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          hide-details
                          label="Address 2"
                          type="address"
                          dense
                          outlined
                          filled
                          background-color="white"
                          v-model="agency.address2"
                        />
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          hide-details
                          label="City"
                          type="address"
                          dense
                          outlined
                          filled
                          background-color="white"
                          v-model="agency.city"
                        />
                      </v-col>
                      <v-col cols="4">
                        <v-select
                          hide-details
                          label="State"
                          :items="stateOptions"
                          dense
                          outlined
                          filled
                          background-color="white"
                          v-model="agency.state"
                        />
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          hide-details
                          label="Zip Code"
                          type="zip"
                          dense
                          outlined
                          filled
                          background-color="white"
                          v-model="agency.zip"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          hide-details
                          label="Additional location information"
                          type="text"
                          auto-grow
                          dense
                          rows="3"
                          outlined
                          filled
                          background-color="white"
                          v-model="agency.additional_location_information"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        style="display:flex; justify-content: flex-end"
                      >
                        <v-btn
                          color="#0A5B8A"
                          class="mt-3"
                          dark
                          @click="updateAgency"
                        >
                          Update Information
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-card flat v-else-if="item == 'Description'">
                  <v-card-text>
                    <v-row
                      ><v-col cols="12">
                        <v-textarea
                          hide-details
                          label="Who we are"
                          dense
                          outlined
                          filled
                          rows="4"
                          auto-grow
                          v-model="agency.who_we_are"
                          background-color="white"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          hide-details
                          label="What we do"
                          dense
                          outlined
                          filled
                          rows="4"
                          auto-grow
                          v-model="agency.what_we_do"
                          background-color="white"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        style="display:flex; justify-content: flex-end"
                      >
                        <v-btn
                          color="#0A5B8A"
                          class="mt-5"
                          dark
                          @click="updateAgency"
                        >
                          Update Information
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-card flat v-else-if="item == 'Contact Information'">
                  <v-card-text>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          hide-details
                          label="Email"
                          type="email"
                          dense
                          outlined
                          filled
                          background-color="white"
                          v-model="agency.email"
                          :rules="[rules.required, rules.email]"
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          hide-details
                          label="Fax"
                          type="text"
                          dense
                          outlined
                          filled
                          background-color="white"
                          v-model="agency.fax"
                        />
                      </v-col>
                      <v-col cols="8">
                        <AppPhoneNumberInput
                          v-model="agency.phone"
                          @update="onUpdatePhone"
                        />
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          hide-details
                          label="Ext"
                          dense
                          outlined
                          filled
                          v-model="agency.phone_ext"
                          background-color="white"
                        />
                      </v-col>

                      <v-col cols="12">
                        <v-text-field
                          hide-details
                          label="Contact Person"
                          type="text"
                          dense
                          outlined
                          filled
                          background-color="white"
                          v-model="agency.contact_person"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          hide-details
                          label="Contact Title"
                          type="text"
                          dense
                          outlined
                          filled
                          background-color="white"
                          v-model="agency.contact_title"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-textarea
                              hide-details
                              label="Additional Notification Recipient(s)"
                              type="text"
                              dense
                              outlined
                              filled
                              rows="3"
                              auto-grow
                              background-color="white"
                              v-model="
                                agency.additional_notification_recipients
                              "
                              v-bind="attrs"
                              v-on="on"
                            />
                          </template>
                          <span
                            >Add a new email address per line.<br />
                            Emails entered here will be sent notifications for
                            need response and event resvps.<br />
                            This field does not appear publicly.</span
                          >
                        </v-tooltip>
                      </v-col>
                      <v-col
                        cols="12"
                        style="display:flex; justify-content: flex-end"
                      >
                        <v-btn
                          color="#0A5B8A"
                          class="mt-5"
                          dark
                          @click="updateAgency"
                        >
                          Update Information
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-card flat v-else-if="item == 'Links'">
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          hide-details
                          label="Official Website"
                          type="website"
                          dense
                          outlined
                          filled
                          background-color="white"
                          v-model="agency.website"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          hide-details
                          label="Facebook"
                          type="facebook"
                          dense
                          outlined
                          filled
                          background-color="white"
                          v-model="agency.facebook"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          hide-details
                          label="Twitter"
                          type="twitter"
                          dense
                          outlined
                          filled
                          background-color="white"
                          v-model="agency.twitter"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        style="display:flex; justify-content: flex-end"
                      >
                        <v-btn
                          color="#0A5B8A"
                          class="mt-5"
                          dark
                          @click="updateAgency"
                        >
                          Update Information
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-card
                  flat
                  v-else-if="item == 'Photos'"
                  style="min-height: 230px;"
                >
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <agency-photo-viewer
                          :editable="true"
                          :agency_id="$route.params.id"
                          ref="viewer"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        style="display:flex; justify-content: flex-end"
                      >
                        <v-btn
                          color="#0A5B8A"
                          class="mt-5"
                          dark
                          @click="onAddPhoto"
                          v-text="'Add Photo'"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-card flat v-else-if="item == 'Account Settings'">
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          hide-details
                          label="New Password"
                          type="password"
                          dense
                          outlined
                          filled
                          background-color="white"
                          :rules="[
                            rules.required,
                            rules.min,
                            rules.passwordMatch
                          ]"
                          v-model="newPassword"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          hide-details
                          label="Confirm Password"
                          type="password"
                          dense
                          outlined
                          filled
                          background-color="white"
                          :rules="[
                            rules.required,
                            rules.min,
                            rules.passwordMatch
                          ]"
                          v-model="confirmPassword"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        style="display:flex; justify-content: flex-end"
                      >
                        <v-btn
                          color="#472583"
                          class="mt-5"
                          dark
                          @click="updatePasswordButtonClicked"
                          v-text="'Update Password'"
                        />
                      </v-col>
                      <v-col>
                        <div class="mt-3 d-flex flex-column">
                          <span class="ma-3 font-24 app-bold-font"
                            >Deactivate Account</span
                          >
                          <span class="ml-5">
                            Click the button below to deactivate your account.
                            <b>This cannot be undone.</b>
                          </span>
                          <v-btn
                            color="red"
                            class="mt-5 mb-3"
                            dark
                            @click="deleteAccount"
                          >
                            Deactivate Account
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </div>
      </div>
      <v-overlay :value="loading">
        <v-container fluid fill-height>
          <v-layout justify-center align-center>
            <v-progress-circular indeterminate color="primary">
            </v-progress-circular>
          </v-layout>
        </v-container>
      </v-overlay>
      <v-snackbar v-model="snackbar">
        {{ errorMessage }}
      </v-snackbar>
      <confirm-delete-dialog
        :deleteDialog="deleteDialog"
        :onClose="onCloseConfirmDelete"
        :onConfirm="onConfirmDelete"
      />
    </v-container>
    <v-container v-else-if="profileType != 'Admin'"
      >Permission Denied</v-container
    >
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import AgencyAvatar from "@/components/agency/AgencyAvatar.vue";
import AgencyPhotoViewer from "@/components/agency/AgencyPhotoViewer.vue";
import ConfirmDeleteDialog from "@/components/common/ConfirmDeleteDialog.vue";
import AppPhoneNumberInput from "@/components/common/AppPhoneNumberInput.vue";
export default {
  components: {
    AgencyAvatar,
    AgencyPhotoViewer,
    ConfirmDeleteDialog,
    AppPhoneNumberInput
  },
  data() {
    return {
      rules: {
        required: value => !!value || "Required.",
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        phone: value => {
          const pattern = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
          return pattern.test(value) || "Invalid phone number.";
        },
        min: v => (v && v.length >= 6) || "Min 6 characters",
        passwordMatch: () =>
          this.newPassword != this.confirmPassword
            ? `Password doesn't match`
            : ""
      },
      stateOptions: [
        "AL",
        "AK",
        "AZ",
        "AR",
        "CA",
        "CO",
        "CT",
        "DE",
        "FL",
        "GA",
        "HI",
        "ID",
        "IL",
        "IN",
        "IA",
        "KS",
        "KY",
        "LA",
        "ME",
        "MD",
        "MA",
        "MI",
        "MN",
        "MS",
        "MO",
        "MT",
        "NE",
        "NV",
        "NH",
        "NJ",
        "NM",
        "NY",
        "NC",
        "ND",
        "OH",
        "OK",
        "OR",
        "PA",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VT",
        "VA",
        "WA",
        "WV",
        "WI",
        "WY",
        "AS",
        "DC",
        "FM",
        "GU",
        "MH",
        "MP",
        "PW",
        "PR",
        "VI"
      ],
      loading: false,
      tab: null,
      tabMenus: [
        "Basic Information",
        "Location",
        "Description",
        "Contact Information",
        "Links",
        "Photos",
        "Account Settings"
      ],
      selectedImage: null,
      errorMessage: null,
      snackbar: false,
      agency: null,
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      deleteDialog: false
    };
  },
  methods: {
    ...mapActions({
      getAgency: "agency/getAgency",
      editAgency: "agency/editAgency",
      deleteAgency: "agency/deleteAgency",
      updatePassword: "agency/updatePassword",
      getCauseIcons: "icon/getCauseIcons"
    }),
    updateAgency() {
      var formData = new FormData();
      this.loading = true;
      this.agency.causesStr = this.agency.causes.join();

      Object.keys(this.agency).map(key => {
        if (this.agency[key] != undefined)
          formData.append(key, this.agency[key]);
      });

      let phone = this.agency.phone;
      if (
        this.phonePayload?.isValid &&
        this.phonePayload.countryCallingCode != "1"
      ) {
        phone = `+${this.phonePayload.countryCallingCode} ${phone}`;
        formData.append("phone", phone);
      }

      if (this.selectedImage) {
        formData.append("image", this.selectedImage);
      }
      this.editAgency(formData)
        .then(() => {
            this.loading = false;
            this.$router.push({ path: `/agency-profile/${this.agency._id}/events` });
        })
        .catch(error => {
          this.loading = false;
          if (error.response == undefined) {
            console.log(error);
          } else {
            this.showErrorMessage(error.response.data.message);
          }
        });
    },
    loadData() {
      this.loading = true;
      this.getAgency(this.$route.params.id)
        .then(data => {
          this.loading = false;
          this.agency = data;
        })
        .catch(error => {
          this.loading = false;
          if (error.response != undefined && error.response.status === 401) {
            this.tokenDialog = true;
          } else {
            console.log(error);
          }
        });
    },
    onImageSelected(image) {
      this.selectedImage = image;
    },
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
    onBack() {
      this.$router.back();
    },
    onAddPhoto() {
      this.$refs.viewer.onAddPhoto();
    },
    updatePasswordButtonClicked() {
      if (!this.newPassword || !this.confirmPassword) {
        this.showErrorMessage("Please fill all the required field");
        return;
      }
      if (this.newPassword != this.confirmPassword) {
        this.showErrorMessage("Password does not match");
        return;
      }
      if (this.newPassword.length < 6) {
        this.showErrorMessage("Please should be at least 6 letters");
        return;
      }
      this.loading = true;
      this.updatePassword({
        _id: this.agency._id,
        new_password: this.newPassword
      })
        .then(() => {
          this.loading = false;
          this.showErrorMessage("Password updated successfully!");
        })
        .catch(error => {
          this.loading = false;
          if (error.response == undefined) {
            console.log(error);
          } else {
            this.showErrorMessage(error.response.data.message);
          }
        });
    },
    deleteAccount() {
      this.deleteDialog = true;
    },
    onCloseConfirmDelete() {
      this.deleteDialog = false;
    },
    onConfirmDelete() {
      this.loading = true;
      this.deleteAgency({ _id: this.agency._id })
        .then(() => {
          this.loading = false;
          this.$router.back();
        })
        .catch(error => {
          this.loading = false;
          if (error.response == undefined) {
            console.log(error);
          } else {
            this.showErrorMessage(error.response.data.message);
          }
        });
    },
    onUpdatePhone(payload) {
      this.phonePayload = payload;
    }
  },
  computed: {
    ...mapGetters({
      profileType: "auth/getType",
      causes: "icon/causes"
    })
  },
  watch: {
    "$route.params.id": function() {
      this.loadData();
    }
  },
  created() {
    this.loadData();
    this.getCauseIcons()
      .then(() => {
        console.log(this.causes);
      })
      .catch(error => console.log(error));
  }
};
</script>
<style scoped>
.page-content {
  overflow: hidden;
  height: 100%;
  padding-right: 16px;
}
.page-content:hover {
  overflow-y: scroll;
  padding-right: 8px;
}
div :deep(.v-tab) {
  font-size: 12px;
  padding: 0 10px;
}
div :deep(.v-slide-group__prev--disabled) {
  display: none;
}
.v-application--is-ltr
  .v-tabs--align-with-title
  > .v-tabs-bar:not(.v-tabs-bar--show-arrows):not(.v-slide-group--is-overflowing)
  > .v-slide-group__wrapper
  > .v-tabs-bar__content
  > .v-tabs-slider-wrapper
  + .v-tab {
  margin-left: 20px;
}
::-webkit-scrollbar {
  height: 4px;
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #febb349a;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f5bd4d;
}

.product-title {
  font-size: 28px;
  color: #472583;
  font-weight: 500;
}
.category-title {
  font-size: 32px;
  color: #472583;
  font-weight: 500;
}
.price-text {
  font-size: 18px;
  color: #472583;
  font-weight: 500;
}
.agency-scroll-section {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}
.subtitle {
  font-size: 28px;
  color: #472583;
  font-weight: 500;
}
</style>
