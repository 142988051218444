import axiosInstance from "@/api/axios";

export default {
  namespaced: true,
  state: {
    notifications: [],
    unreadCount: 0
  },
  getters: {
    getAllNotifications(state) {
      return state.notifications;
    },
    getUnreadCount(state) {
      return state.unreadCount;
    }
  },
  mutations: {
    setNotifications(state, data) {
      state.notifications = data;
    },
    loadMoreNotifications(state, data) {
      data.map(d => {
        if (state.notifications.filter(e => e._id == d._id).length == 0) {
          state.notifications.push(d);
        }
      });
    },
    loadMoreAgencyNotifications(state, data) {
      data.map(d => {
        if (state.agencyNotifications.indexOf(d) == -1)
          state.agencyNotifications.push(d);
      });
    },
    addNotification(state, data) {
      const item = state.notifications.find(item => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.notifications.unshift(data);
      }
    },
    editNotification(state, data) {
      const item = state.notifications.find(item => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.notifications.push(data);
      }
    },
    deleteNotification(state, data) {
      state.notifications = state.notifications.filter(q => q._id !== data._id);
    },
    deleteAllNotification(state, data) {
      state.notifications = state.notifications.filter(q => q._id !== data._id);
    },
    setPlaying(state, data) {
      state.playing = data;
    },
    setUnreadCount(state, data) {
      state.unreadCount = data;
    },
    updateUnreadCount(state) {
      state.unreadCount = 0;
      state.notifications.map(n => (state.unreadCount += n.read ? 0 : 1));
    }
  },
  actions: {
    fetchAllNotifications({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("notification/all")
          .then(res => {
            if (res.data.success == true) {
              commit("setNotifications", res.data.res);
              commit("updateUnreadCount");
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    loadMoreNotifications({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("notification/load-more", params)
          .then(res => {
            if (res.data.success == true) {
              commit("loadMoreNotifications", res.data.res);
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    getNotification({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("notification/get", params)
          .then(res => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    markAsRead({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("notification/read", params, {
            Authorization: rootState.auth.token
          })
          .then(res => {
            if (res.data.success == true) {
              commit("editNotification", res.data.res);
              commit("updateUnreadCount");
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    markAllAsReadNotifications({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("notification/read-all", params, {
            Authorization: rootState.auth.token
          })
          .then(res => {
            if (res.data.success == true) {
              commit("setNotifications", res.data.res);
              commit("updateUnreadCount");
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    deleteNotification({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("notification/delete", params)
          .then(res => {
            if (res.data.success == true) {
              commit("deleteNotification", res.data.res);
              commit("updateUnreadCount");
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    deleteAllNotifications({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("notification/deleteAll")
          .then(res => {
            if (res.data.success == true) {
              commit("setNotifications", []);
              commit("updateUnreadCount");
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    getUnreadCount({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("notification/unread-count")
          .then(res => {
            if (res.data.success == true) {
              commit("setUnreadCount", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  }
};
