var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-center"},[_c('div',{staticClass:"d-flex flex-column justify-center",staticStyle:{"max-width":"1000px","width":"100%"}},[_c('div',{staticClass:"d-flex flex-column w-100 position-relative"},[(_vm.volunteer)?_c('v-img',{staticStyle:{"flex-grow":"1"},attrs:{"src":_vm.profile._id == _vm.volunteer._id
            ? _vm.profile.bgImage
              ? _vm.profile.bgImage
              : require('@/assets/background0.png')
            : _vm.volunteer && _vm.volunteer.bgImage
            ? _vm.volunteer.bgImage
            : require('@/assets/background0.png'),"width":"100%","aspect-ratio":_vm.$vuetify.breakpoint.smAndDown ? 414 / 180 : 1654 / 455}}):_vm._e(),_c('div',{staticClass:"hidden-sm-and-down",staticStyle:{"padding-left":"300px","background-color":"#eee4ff"}},[_c('v-tabs',{attrs:{"background-color":"#eee4ff"},on:{"change":_vm.onSelected},model:{value:(_vm.selectedPage),callback:function ($$v) {_vm.selectedPage=$$v},expression:"selectedPage"}},_vm._l((_vm.menus),function(item){return _c('v-tab',{key:item.title},[_vm._v(" "+_vm._s(item.title)+" ")])}),1)],1),(_vm.volunteer)?_c('div',{staticClass:"hidden-md-and-up",staticStyle:{"background-color":"#eee4ff"},style:(_vm.$vuetify.breakpoint.xs && _vm.profile._id != _vm.volunteer._id
            ? 'margin-top: 100px;'
            : 'margin-top: 60px;')},[_c('v-tabs',{attrs:{"background-color":"#eee4ff","grow":""},on:{"change":_vm.onSelected},model:{value:(_vm.selectedPage),callback:function ($$v) {_vm.selectedPage=$$v},expression:"selectedPage"}},_vm._l((_vm.menus),function(item){return _c('v-tab',{key:item.title},[_vm._v(" "+_vm._s(item.title)+" ")])}),1)],1):_vm._e(),_c('div',{class:_vm.avatarClasses,style:(_vm.avatarStyles),on:{"click":_vm.onEditName}},[_c('img',{staticClass:"avatar-img",attrs:{"src":_vm.volunteer?._id == _vm.profile._id
                ? _vm.profile?.midThumbnail
                  ? _vm.profile?.midThumbnail
                  : _vm.profile?.image
                  ? _vm.profile?.image
                  : _vm.placeholderImage
                : _vm.volunteer?.midThumbnail
                ? _vm.volunteer?.midThumbnail
                : _vm.volunteer?.image
                ? _vm.volunteer?.image
                : _vm.placeholderImage,"alt":_vm.name}})]),_c('div',{staticClass:"cursor-pointer",on:{"click":_vm.onEditName}},[(_vm.volunteer)?_c('agency-avatar',{staticClass:"d-none",staticStyle:{"position":"absolute"},style:(_vm.$vuetify.breakpoint.xs && _vm.profile._id != _vm.volunteer._id
              ? 'bottom: 110px; left: 50%; transform: translate(-50%,0)'
              : _vm.$vuetify.breakpoint.xs && _vm.profile._id == _vm.volunteer._id
              ? 'bottom: 60px; left: 50%; transform: translate(-50%,0)'
              : _vm.$vuetify.breakpoint.sm
              ? 'bottom: 60px; left: 40px;'
              : 'bottom: 0px; left: 40px;'),attrs:{"img":_vm.volunteer?._id == _vm.profile._id
              ? _vm.profile?.midThumbnail
                ? _vm.profile?.midThumbnail
                : _vm.profile?.image
                ? _vm.profile?.image
                : _vm.placeholderImage
              : _vm.volunteer?.midThumbnail
              ? _vm.volunteer?.midThumbnail
              : _vm.volunteer?.image
              ? _vm.volunteer?.image
              : _vm.placeholderImage,"size":_vm.$vuetify.breakpoint.xs ? 140 : 180,"name":_vm.volunteer.name,"editable":false,"onImageSelected":_vm.onImageSelected}}):_vm._e()],1),(_vm.volunteer && _vm.volunteer._id == _vm.profile._id)?_c('circle-icon-button',{staticStyle:{"position":"absolute","top":"20px","right":"20px"},attrs:{"text":"Edit Background","icon":"mdi-image-edit","onClicked":_vm.onChangeBackground}}):_vm._e(),_c('div',{staticStyle:{"position":"absolute","bottom":"60px"},style:((_vm.volunteer &&
          _vm.volunteer._id != _vm.profile._id &&
          !(!_vm.getFanned && _vm.profileType == 'Agency') &&
          _vm.profileType != 'Admin'
            ? 'width: 298px;'
            : 'width: 160px;') +
            (_vm.$vuetify.breakpoint.xs
              ? 'left: 50%; transform: translate(-50%,0)'
              : 'right: 30px;'))},[(
            _vm.volunteer &&
              _vm.volunteer._id != _vm.profile._id &&
              !(!_vm.getFanned && _vm.profileType == 'Agency') &&
              _vm.profileType != 'Admin'
          )?_c('v-btn',{attrs:{"color":"#B5E539","loading":_vm.becomingFan},on:{"click":_vm.becomeFan}},[_vm._v(" "+_vm._s(_vm.profileType == "Volunteer" ? _vm.getFanned ? "Unfollow" : "Follow" : "Fan of you")+" "),_c('v-icon',{staticClass:"ml-2",attrs:{"size":"16"}},[_vm._v(_vm._s(_vm.getFanned ? "mdi-account-check" : "mdi-account-plus")+" ")])],1):_vm._e(),(_vm.volunteer && _vm.volunteer._id != _vm.profile._id)?_c('v-btn',{staticClass:"ml-5",attrs:{"color":"#B5E539"},on:{"click":_vm.goToMessage}},[_vm._v("Message "),_c('v-icon',{staticClass:"ml-2",attrs:{"size":"16"}},[_vm._v("mdi-message")])],1):_vm._e()],1)],1),_c('router-view')],1),_c('volunteer-profile-edit-background',{attrs:{"dialog":_vm.bgDialog,"onClose":_vm.closeBGDialog}}),(_vm.editNameDialog)?_c('volunteer-profile-edit-name',{attrs:{"dialog":_vm.editNameDialog,"onClose":_vm.onCloseEditDialog}}):_vm._e(),_c('v-snackbar',{model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }