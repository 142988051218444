<template>
  <div class="mt-5 mx-5" style="width: 100%">
    <div class="d-flex flex-row justify-between white--text" v-if="need">
      <span
        class="url-button app-medium-font"
        :class="$vuetify.breakpoint.xs ? 'font-16' : 'font-20'"
        @click="goToNeed"
      >
        {{ need.title }}
      </span>
      <span
        class="url-button"
        @click="goToResponse"
        :class="$vuetify.breakpoint.xs ? 'font-12' : 'font-16'"
        >View responses</span
      >
    </div>
    <v-card v-if="need">
      <v-card-title
        class="d-flex flex-row align-center ml-3 mt-5"
        v-if="volunteer"
      >
        <editable-avatar
          :image="
            volunteer.midThumbnail ? volunteer.midThumbnail : volunteer.image
          "
          :editable="false"
          :size="$vuetify.breakpoint.xs ? 40 : 60"
        />
        <div
          style="font-weight: bold; "
          class="ml-3 url-button"
          :class="$vuetify.breakpoint.xs ? 'font-16' : 'font-20'"
          @click="goToProfile"
        >
            {{ volunteer.first_name && volunteer.last_name ? volunteer.first_name + " " + volunteer.last_name : volunteer.name }}
        </div>
        
      </v-card-title>
      <v-card-subtitle class="mt-0">
        <div
          class="mb-2"
          v-if="need.start_date"
          :class="$vuetify.breakpoint.xs ? 'font-12' : 'font-14'"
        >
          Started at {{ need.start_date }}
        </div>
        <v-divider />
        <v-row class="my-0">
          <v-col
            cols="3"
            class="d-flex flex-column align-center"
            :class="$vuetify.breakpoint.xs ? 'font-12' : 'font-14'"
          >
            Last 24 hrs
            <div class="mt-1">
              <span
                style="font-weight: bold;"
                :style="
                  $vuetify.breakpoint.xs
                    ? 'font-size: 18px;'
                    : 'font-size: 20px;'
                "
                >{{
                  summary.lastday ? formatDifferent(summary.lastday) : 0
                }}</span
              >
              hrs
            </div>
          </v-col>
          <v-col cols="3" class="d-flex flex-column align-center">
            This week
            <div class="mt-1">
              <span
                style="font-weight: bold;"
                :style="
                  $vuetify.breakpoint.xs
                    ? 'font-size: 18px;'
                    : 'font-size: 20px;'
                "
              >
                {{ summary.week ? formatDifferent(summary.week) : 0 }}
              </span>
              hrs
            </div>
          </v-col>
          <v-col cols="3" class="d-flex flex-column align-center">
            Last week
            <div class="mt-1">
              <span
                style="font-weight: bold;"
                :style="
                  $vuetify.breakpoint.xs
                    ? 'font-size: 18px;'
                    : 'font-size: 20px;'
                "
                >{{
                  summary.lastWeek ? formatDifferent(summary.lastWeek) : 0
                }}</span
              >
              hrs
            </div>
          </v-col>
          <v-col cols="3" class="d-flex flex-column align-center">
            Since start
            <div class="mt-1">
              <span
                style="font-weight: bold; "
                :style="
                  $vuetify.breakpoint.xs
                    ? 'font-size: 18px;'
                    : 'font-size: 20px;'
                "
                >{{ summary.total ? formatDifferent(summary.total) : 0 }}</span
              >
              hrs
            </div>
          </v-col>
        </v-row>
        <v-divider />
      </v-card-subtitle>
    </v-card>
    <v-card class="mt-4">
      <v-card-title :class="$vuetify.breakpoint.xs ? 'font-16' : 'font-20'">
        Timesheet this week
      </v-card-title>
      <v-card-subtitle class="mt-0">
        <v-divider />
        <v-row class="mx-0">
          <v-col
            class="d-flex flex-column align-center"
            v-for="(week, index) in weekNames"
            :key="index"
          >
            <div
              style="font-weight: bold;"
              :class="$vuetify.breakpoint.xs ? 'font-10' : 'font-14'"
            >
              {{ week }}
            </div>
            <div
              v-if="weekDates.length > index"
              :class="$vuetify.breakpoint.xs ? 'font-8' : 'font-10'"
            >
              {{ weekDates[index] }}
            </div>
          </v-col>
          <v-col class="d-flex flex-column align-center">
            <div
              style="font-weight: bold;"
              :class="$vuetify.breakpoint.xs ? 'font-10' : 'font-14'"
            >
              Hours
            </div>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col
            class="d-flex flex-column align-center"
            :style="todayWeekIndex >= index ? 'background: lightyellow' : ''"
            v-for="(weekhour, index) in weekHours"
            :key="index"
          >
            <div
              v-if="todayWeekIndex >= index"
              :class="$vuetify.breakpoint.xs ? 'font-10' : 'font-14'"
            >
              {{ weekhour ? formatDifferent(weekhour) : "-" }}
            </div>
          </v-col>
          <v-col class="d-flex flex-column align-center">
            <div :class="$vuetify.breakpoint.xs ? 'font-10' : 'font-14'">
              {{ formatDifferent(weekTotal) }}
            </div>
          </v-col>
        </v-row>
        <v-divider />
      </v-card-subtitle>
    </v-card>
    <v-card class="mt-4 mb-4">
      <v-card-title
        class="d-flex flex-row justify-between"
        :class="$vuetify.breakpoint.xs ? 'font-16' : 'font-20'"
      >
        Work diary
        <v-btn
          :small="$vuetify.breakpoint.smAndUp"
          color="#0a5b8a"
          @click="addManualTime"
          :x-small="$vuetify.breakpoint.xs"
          dark
          >Add Manual Time</v-btn
        >
      </v-card-title>
      <v-card-subtitle class="d-flex flex-row align-center mb-0 pb-0 mt-0">
        <v-btn
          icon
          class="mr-2"
          @click="selectPreviousDay"
          v-if="diaryDate"
          :x-small="$vuetify.breakpoint.xs"
        >
          <v-icon color="#b5e539">mdi-chevron-left</v-icon>
        </v-btn>
        <div class="mx-5">
          <v-menu
            v-model="date_picker"
            :close-on-content-click="false"
            :return-value.sync="diaryPickerDate"
            transition="scale-transition"
            offset-y
            min-width="auto"
            :top="$vuetify.breakpoint.xs"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="diaryDate"
                readonly
                solo
                flat
                outlined
                dense
                v-bind="attrs"
                v-on="on"
                autocomplete="off"
                append-icon="mdi-calendar-month-outline"
                :style="
                  $vuetify.breakpoint.xs ? 'width: 138px;' : 'width: 280px;'
                "
                hide-details=""
              />
            </template>
            <v-date-picker
              v-model="diaryPickerDate"
              no-title
              scrollable
              show-adjacent-months
              :max="maxDate"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="date_picker = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="onSelectDiaryDate(diaryPickerDate)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </div>
        <v-btn
          icon
          :class="$vuetify.breakpoint.xs ? 'ml-0' : 'ml-2'"
          @click="selectNextDay"
          v-if="diaryDate && !isToday"
          :x-small="$vuetify.breakpoint.xs"
        >
          <v-icon color="#b5e539">mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn text @click="selectToday" :small="$vuetify.breakpoint.xs"
          >Today</v-btn
        >
      </v-card-subtitle>
      <v-card-subtitle class="mt-2 pt-0">
        <v-divider class="mb-2" />
        <div
          style="font-weight: bold;"
          class="mt-3 ml-3"
          :class="$vuetify.breakpoint.xs ? 'font-14' : 'font-16'"
        >
          Total: {{ formatDifferent(dayTotal) }} hrs
        </div>
        <div
          class="d-flex flex-row ml-3 mb-2"
          :class="$vuetify.breakpoint.xs ? 'font-12' : 'font-14'"
        >
          <div class="mr-2">Tracked: {{ formatDifferent(dayTracked) }} hrs</div>
          <div>Manual: {{ formatDifferent(dayManual) }} hrs</div>
        </div>
        <div>
          <v-data-table
            :headers="headers"
            :items="diaryList"
            :items-per-page="15"
            :loading="loading"
            class="elevation-0"
          >
            <template v-slot:[`item.time`]="{ item }">
              <div
                :class="{
                  'disabled-font-color': item.availability == 'Rejected'
                }"
              >
                {{
                  `${formatTime(item.start)} - ${
                    item.status == "Tracking" ? "" : formatTime(item.end)
                  } (${formatDifferent(item.minutes)} hrs)`
                }}
              </div>
            </template>
            <template v-slot:[`item.shift`]="{ item }">
              {{ item.shift.title }}
            </template>
            <template v-slot:[`item.type`]="{ item }">
              {{
                item.availability == "Rejected"
                  ? "Rejected"
                  : item.status == "Tracking"
                  ? "Working now"
                  : item.type
              }}
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    style="border-radius: 1px; border-color: #0A5B8A; border-style: solid; border-radius: 36px;"
                    small
                    @click="onEditDiaryItem(item)"
                    v-bind="attrs"
                    v-on="on"
                    :disabled="item.type == 'Tracked'"
                  >
                    <v-icon color="#0A5B8A" style="margin-top: 2px;" size="18"
                      >mdi-pencil</v-icon
                    >
                  </v-btn>
                </template>
                Edit
              </v-tooltip>
              <v-tooltip bottom v-if="item.type == 'Manual'">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    style="border-radius: 1px; border-color: #0A5B8A; border-style: solid; border-radius: 36px;"
                    small
                    @click="onDeleteDiaryItem(item)"
                    v-bind="attrs"
                    v-on="on"
                    class="ml-3"
                  >
                    <v-icon color="#0A5B8A" style="margin-top: 2px;" size="18"
                      >mdi-trash-can</v-icon
                    >
                  </v-btn>
                </template>
                Delete
              </v-tooltip>
              <v-tooltip bottom v-else>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    style="border-radius: 1px; border-color: #0A5B8A; border-style: solid; border-radius: 36px;"
                    small
                    @click="onRejectDiaryItem(item)"
                    v-bind="attrs"
                    v-on="on"
                    class="ml-3"
                    :disabled="item.availability == 'Rejected'"
                  >
                    <v-icon color="#0A5B8A" style="margin-top: 2px;" size="18"
                      >mdi-close</v-icon
                    >
                  </v-btn>
                </template>
                {{ item.availability == "Rejected" ? "Rejected" : "Reject" }}
              </v-tooltip>
            </template>
          </v-data-table>
        </div>
      </v-card-subtitle>
    </v-card>
    <agency-add-manual-time
      :dialog="addManualDialog"
      :onAddedTime="onAddedTime"
      :onCloseDialog="onCloseTimeDialog"
      :responses="responses"
      :volunteer_id="volunteer._id"
      :initDate="diaryDate"
      v-if="volunteer && addManualDialog"
    />
    <agency-edit-manual-time
      :dialog="editManualDialog"
      :onEditedTime="onEditedTime"
      :onCloseDialog="onCloseTimeDialog"
      :responses="responses"
      :volunteer_id="volunteer._id"
      :timeslot="selectedItem"
      v-if="volunteer && editManualDialog"
    />
    <agency-delete-manual-time
      :dialog="deleteManualDialog"
      :onDeletedTime="onDeletedTime"
      :onCloseDialog="onCloseTimeDialog"
      :responses="responses"
      :volunteer_id="volunteer._id"
      :timeslot="selectedItem"
      v-if="volunteer && deleteManualDialog"
    />
    <v-snackbar v-model="snackbar">
      {{ snackMessage }}
    </v-snackbar>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import EditableAvatar from "@/components/EditableAvatar.vue";
import dateFormat from "dateformat";
import AgencyAddManualTime from "./AgencyAddManualTime.vue";
import AgencyEditManualTime from "./AgencyEditManualTime.vue";
import AgencyDeleteManualTime from "./AgencyDeleteManualTime.vue";
export default {
  components: {
    EditableAvatar,
    AgencyAddManualTime,
    AgencyEditManualTime,
    AgencyDeleteManualTime
  },
  data() {
    return {
      loading: false,
      need: null,
      snackbar: false,
      snackMessage: null,
      volunteer: null,
      weekNames: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      weekDates: [],
      weekHours: [],
      weekTotal: 0,
      todayWeekIndex: 0,
      today: this.convertTZ("", this.timezone),
      headers: [
        { text: "Time", value: "time" },
        { text: "Type", value: "type" },
        { text: "Shift", value: "shift" },
        { text: "Memo", value: "memo" },
        { text: "Action", value: "action" }
      ],
      diaryList: [],
      diaryDate: "",
      date_picker: false,
      diaryPickerDate: null,
      maxDate: null,
      addManualDialog: false,
      editManualDialog: false,
      deleteManualDialog: false,
      responses: [],
      dayTotal: 0,
      dayTracked: 0,
      dayManual: 0,
      summary: {},
      selectedItem: null
    };
  },
  methods: {
    ...mapActions({
      getNeedDetails: "need/getNeedDetails",
      getVolunteer: "volunteer/getVolunteer",
      fetchUserResponses: "response/fetchUserResponses",
      fetchSummary: "timeslot/fetchSummary",
      fetchDayTimeSlots: "timeslot/fetchDayTimeSlots",
      fetchWeekTimeSheet: "timeslot/fetchWeekTimeSheet",
      confirmTime: "timeslot/confirm",
      rejectTime: "timeslot/reject",
      fetchProfile: "auth/fetchProfile"
    }),
    getNeed() {
      this.loading = true;
      this.getNeedDetails({ _id: this.$route.query.need_id })
        .then(need => {
          this.loading = false;
          this.need = need;
          if (
            this.profile &&
            this.profile._id &&
            need.agency != this.profile._id
          ) {
            console.log(need.agency, this.profile._id);
            this.$router.push({
              name: "404"
            });
          } else {
            this.getUser();
          }
        })
        .catch(error => {
          this.loading = false;
          if (error.response != undefined && error.response.status === 401) {
            this.tokenDialog = true;
          } else {
            console.log(error);
          }
        });
    },
    getUserResponses() {
      this.loading = true;
      this.fetchUserResponses({
        need_id: this.$route.query.need_id,
        user_id: this.$route.query.volunteer_id || this.$route.query.company_id
      })
        .then(res => {
          this.loading = false;
          this.responses = res;
        })
        .catch(error => {
          this.loading = false;
          if (error.response != undefined && error.response.status === 401) {
            this.tokenDialog = true;
          } else {
            console.log(error);
          }
        });
    },
    getUser() {
      this.getVolunteer({ _id: this.$route.query.volunteer_id || this.$route.query.company_id })
        .then(res => {
          this.loading = false;
          this.volunteer = res;
          this.getUserResponses();
        })
        .catch(error => {
          this.loading = false;
          this.$router.push({
            name: "404"
          });
          console.log(error);
        });
    },
    goToNeed() {
      this.$router.push({
        name: "agency-need-details",
        query: {
          _id: this.need._id
        }
      });
    },
    goToResponse() {
      this.$router.push({
        name: "agency-responses-feeds",
        query: { _id: this.need._id }
      });
    },
    goToProfile() {
      if (this.volunteer.type === "Volunteer") {
           this.$router.push({
        name: "volunteer-profile-details",
        params: { id: this.volunteer._id }
      });
      } else if (this.volunteer.type === "Company"){
        console.log(this.volunteer);
        
           this.$router.push({
        name: "company-profile-details",
        params: { id: this.volunteer._id }
      });
      }
   
    },
    formatTime(date) {
      return dateFormat(this.convertTZ(date, this.timezone), "hh:MM TT");
    },
    formatDifferent(diff) {
      var hrs = Math.floor(diff / 60);
      var str = `${hrs}:`;
      // if (hrs > 9) str += `${hrs}:`;
      // else str += `0${hrs}:`;
      var mins = diff % 60;
      if (mins > 9) str += `${mins}`;
      else str += `0${mins}`;
      return str;
    },
    convertTZ(date, tzString) {
      if (!date) {
        return new Date(
          new Date().toLocaleString("en-US", {
            timeZone: tzString ? tzString : "America/New_York"
          })
        );
      }
      return new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString(
          "en-US",
          {
            timeZone: tzString ? tzString : "America/New_York"
          }
        )
      );
    },
    loadTimeSheet() {
      this.todayWeekIndex = (this.today.getDay() + 6) % 7;
      var sheetDay = this.convertTZ("", this.timezone);
      sheetDay.setDate(this.today.getDate() - this.todayWeekIndex);
      var endDay = this.convertTZ("", this.timezone);
      endDay.setDate(sheetDay.getDate() + 8);
      this.weekDates = [];
      while (sheetDay.getTime() < endDay.getTime()) {
        this.weekDates.push(`${sheetDay.getMonth() + 1}/${sheetDay.getDate()}`);
        sheetDay.setDate(sheetDay.getDate() + 1);
      }
      this.getWeekSheet();
    },

    onSelectDiaryDate(dateStr) {
      let date = new Date();
      date.setFullYear(parseInt(dateStr.substring(0, 4)));
      date.setDate(parseInt(dateStr.substring(8, 10)));
      date.setMonth(parseInt(dateStr.substring(5, 7)) - 1);
      if (this.$vuetify.breakpoint.xs) {
        this.diaryDate = dateFormat(date, "ddd, mmm d");
      } else {
        this.diaryDate = dateFormat(date, "dddd, mmmm d, yyyy");
      }
      this.date_picker = false;
    },
    selectToday() {
      if (this.$vuetify.breakpoint.xs) {
        this.diaryDate = dateFormat(this.today, "ddd, mmm d");
      } else {
        this.diaryDate = dateFormat(this.today, "dddd, mmmm d, yyyy");
      }
    },
    selectPreviousDay() {
      var dd = new Date(this.diaryDate);
      dd.setDate(dd.getDate() - 1);
      if (this.$vuetify.breakpoint.xs) {
        this.diaryDate = dateFormat(dd, "ddd, mmm d");
      } else {
        this.diaryDate = dateFormat(dd, "dddd, mmmm d, yyyy");
      }
    },
    selectNextDay() {
      var dd = new Date(this.diaryDate);
      dd.setDate(dd.getDate() + 1);
      if (this.$vuetify.breakpoint.xs) {
        this.diaryDate = dateFormat(dd, "ddd, mmm d");
      } else {
        this.diaryDate = dateFormat(dd, "dddd, mmmm d, yyyy");
      }
    },
    addManualTime() {
      this.addManualDialog = true;
    },
    onCloseTimeDialog() {
      this.addManualDialog = false;
      this.editManualDialog = false;
      this.deleteManualDialog = false;
    },
    onAddedTime(timeslot) {
      this.addManualDialog = false;
      for (var i = 0; i < this.responses.length; i++) {
        if (this.responses[i].shift._id == timeslot.shift_id) {
          timeslot = { ...timeslot, shift: this.responses[i].shift };
        }
      }
      this.diaryList.push(timeslot);
      this.calculateDayTotal();
      this.getWeekSheet();
    },
    onEditedTime(timeslot) {
      this.editManualDialog = false;
      for (var i = 0; i < this.responses.length; i++) {
        if (this.responses[i].shift._id == timeslot.shift_id) {
          timeslot = { ...timeslot, shift: this.responses[i].shift };
        }
      }
      this.diaryList = this.diaryList.map(d =>
        d._id == timeslot._id ? timeslot : d
      );
      this.calculateDayTotal();
      this.getWeekSheet();
    },
    onDeletedTime(timeslot) {
      this.deleteManualDialog = false;
      this.diaryList = this.diaryList.filter(d => d._id !== timeslot._id);
      this.calculateDayTotal();
      this.getWeekSheet();
    },
    onDeleteDiaryItem(item) {
      this.selectedItem = item;
      this.deleteManualDialog = true;
    },
    onEditDiaryItem(item) {
      this.selectedItem = item;
      this.editManualDialog = true;
    },
    onRejectDiaryItem(timeslot) {
      console.log(timeslot);
      this.rejectTime({ _id: timeslot._id })
        .then(() => {
          timeslot.availability = "Rejected";
          this.calculateDayTotal();
          this.getWeekSheet();
        })
        .catch(error => {
          console.log(error.response.data.message);
        });
    },
    onConfirmDiaryItem(timeslot) {
      this.confirmTime(timeslot)
        .then(() => {
          timeslot.availability = "Availability";
          this.calculateDayTotal();
          this.getWeekSheet();
        })
        .catch(error => {
          console.log(error.response.data.message);
        });
    },
    getWeekSheet() {
      this.fetchWeekTimeSheet({
        user_id: this.$route.query.volunteer_id || this.$route.query.company_id,
        need_id: this.$route.query.need_id
      })
        .then(res => {
          this.loading = false;
          this.weekHours = res;
          this.weekTotal = 0;
          for (var i = 0; i < this.weekHours.length; i++) {
            this.weekTotal += this.weekHours[i];
          }
          while (this.weekHours.length < 7) {
            this.weekHours.push(0);
          }
        })
        .catch(error => {
          this.loading = false;
          console.log(error.response.data.message);
        });
      this.getSummary();
    },
    getSummary() {
      this.fetchSummary({
        user_id: this.$route.query.volunteer_id || this.$route.query.company_id,
        need_id: this.$route.query.need_id
      })
        .then(res => {
          this.summary = res;
        })
        .catch(error => {
          this.loading = false;
          console.log(error.response.data.message);
        });
    },
    getTimeSlots() {
      this.loading = true;
      this.fetchDayTimeSlots({
        user_id: this.$route.query.volunteer_id || this.$route.query.company_id,
        date: this.diaryDate,
        need_id: this.$route.query.need_id
      })
        .then(res => {
          this.loading = false;
          this.diaryList = res;
          this.calculateDayTotal();
        })
        .catch(error => {
          this.loading = false;
          console.log(error.response.data.message);
        });
    },
    calculateDayTotal() {
      this.dayTotal = 0;
      this.dayTracked = 0;
      this.dayManual = 0;
      for (var i = 0; i < this.diaryList.length; i++) {
        this.dayTotal += this.diaryList[i].minutes;
        if (this.diaryList[i].type == "Tracked") {
          this.dayTracked += this.diaryList[i].minutes;
        } else if (this.diaryList[i].type == "Manual") {
          this.dayManual += this.diaryList[i].minutes;
        }
      }
      this.fetchProfile();
    }
  },
  computed: {
    ...mapState("auth", {
      profile: "profile",
      profileType: "type"
    }),
    ...mapGetters({
      timezone: "usersetting/getTimeZone"
    }),
    isToday() {
      var date = new Date(this.diaryDate);
      if (
        this.today.getDate() == date.getDate() &&
        this.today.getMonth() == date.getMonth() &&
        this.today.getFullYear() == date.getFullYear()
      )
        return true;
      return false;
    }
  },
  watch: {
    diaryDate(newValue) {
      if (
        newValue &&
        this.$route.query.volunteer_id || this.$route.query.company_id &&
        this.$route.query.need_id
      ) {
        this.getTimeSlots();
      }
    }
  },
  mounted() {
    this.getNeed();
    this.today = this.convertTZ("", this.timezone);
    this.maxDate = dateFormat(this.today, "yyyy-mm-dd");
    if (this.$vuetify.breakpoint.xs) {
      this.diaryDate = dateFormat(this.today, "ddd, mmm d");
    } else {
      this.diaryDate = dateFormat(this.today, "dddd, mmmm d, yyyy");
    }
    this.loadTimeSheet();
  }
};
</script>
