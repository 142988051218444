<template>
  <v-container
    fluid
    style="height: 100%; margin:0px; padding:0px; min-height: inherit"
    class="d-flex flex-row"
  >
    <company-profile-left-section class="hidden-sm-and-down" />
    <company-profile-feed-section />
  </v-container>
</template>
<script>
import CompanyProfileFeedSection from "../../company/profile/CompanyProfileFeedSection.vue";
import CompanyProfileLeftSection from "../../company/profile/CompanyProfileLeftSection.vue";

export default {
  components: { CompanyProfileFeedSection, CompanyProfileLeftSection }
};
</script>
