<template>
  <v-dialog
    v-model="internalDialog"
    persistent
    max-width="600px"
    style="z-index:200000001;"
  >
    <v-card>
      <v-card-title class="flex-row justify-between d-flex app-bold-font">
        Delete Team Challenge
        <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <div >
        <v-card-text class="app-regular-font">
          Are you sure want to delete the Team Challenge <b>{{respectiveCompetition.title}}</b> ?
        </v-card-text>
        <v-card-text v-if="message">
          <v-alert dense type="error" v-text="message" v-if="message" />
        </v-card-text>
      </div>
      <v-card-actions class="flex-row justify-center pb-6 mt-3 d-flex">
        <v-btn
          color="#0a5b8a"
          @click="ondelete(respectiveCompetition)"
          class="white--text"
          width="150"
          :loading="loading"
          >Delete</v-btn>
           <v-btn color="red"  class="white--text"
          width="150" @click="onClose()">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    onClose: {
      type: Function,
      required: true
    },
    respectiveCompetition: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      internalDialog: this.dialog,
      message: null,
      loading: false
    };
  },
  watch: {
    dialog(val) {
      this.internalDialog = val;
    },
    internalDialog(val) {
      this.$emit('update:dialog', val);
    }
  },
  methods: {
    ...mapActions({
      deleteCompetition: "competition/deleteCompetition"
    }),
    ondelete(id) {
      this.loading = true;
      const params = {
        competitionId: id.competition_id
      };
      this.deleteCompetition(params)
        .then(() => {
          this.loading = false;
          this.onClose(); // Call the onClose method after deleting
        })
        .catch(error => {
          this.loading = false;
          this.message = error.response.data;
        });
    }
  },
  computed: {
    ...mapState("auth", {
      profile: "profile"
    })
  }
};
</script>
<style scoped>
@media (max-width: 600px) {
  div :deep(.v-dialog) {
    margin: 0;
    /* padding-top: 17px; */
    /* padding-bottom: 17px; */
    /* height: 100%; */
  }
}
@media (min-width: 600px) {
  .v-dialog__content {
    z-index: 2000001 !important;
  }
  div :deep(.v-dialog) {
    /* height: 80vh; */
    width: 70%;
    max-width: 900px;
    overflow-y: hidden;
  }
}
.v-dialog::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.v-dialog::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.v-dialog::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
.v-dialog::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}

.time-picker {
  width: 107px;
  border-color: #d094ea !important;
  border-width: 1px;
  height: 40px;
}
.time-picker :deep(input.display-time) {
  height: 40px !important;
  width: 100%;
  border-radius: 5px;
  border-color: rgba(0, 0, 0, 0.38);
  padding: 0 15px;
}
.time-picker :deep(input.display-time:hover) {
  border-color: currentColor;
}
.time-picker :deep(input.display-time:focus-visible) {
  border-color: #d094ea !important;
  border-width: 1px;
}
.time-picker :deep(.clear-btn) {
  padding: 5px 16px;
  margin-top: 5px;
}
</style>
