<template>
  <div class="w-100 px-2 mt-5">
    <v-container fluid class="pt-0 pb-15 d-flex flex-column">
      <span class="white--text font-30 app-medium-font ml-5 mb-3">{{
        $t("members")
      }}</span>
      <v-data-table
        :headers="headers"
        :items="memberList"
        :items-per-page="10"
        :loading="loading"
        @click:row="onSelectClient"
        class="elevation-0"
      >
        <template v-slot:no-data>
          <div class="url-button app-bold-font">
            No Data Available
          </div>
        </template>
        <template v-slot:[`item.image`]="{ item }">
          <div
            @click="goToProfile(item)"
            style="width: 200px;"
            class="d-flex flex-column align-center mt-3"
          >
            <v-img
              :src="
                item.user_id && item.user_id.midThumbnail
                  ? item.user_id.midThumbnail
                  : require('@/assets/background0.png')
              "
              width="200"
              style="flex-grow: 1"
              :aspect-ratio="
                $vuetify.breakpoint.smAndDown ? 414 / 180 : 1654 / 455
              "
              class="rounded-lg rounded-b-0 position-absolute"
            />
            <editable-avatar
              :image="item.user_id && item.user_id.thumbnail ? item.user_id.thumbnail : item.image"
              :editable="false"
              :size="50"
              class="mt-5"
            />
          </div>
          <div class="url-button app-bold-font" @click="goToProfile(item)">
            {{ item.user_id?.first_name }} {{ item.user_id?.last_name }}
          </div>
        </template>
        <template v-slot:[`item.agency_name`]="{ item }">
          <div
            @click="goToProfile(item)"
            style="width: 200px;"
            class="d-flex flex-column align-center mt-3"
          >
             <v-img
              :src="
                item.agency_id && item.agency_id.thumbnail
                  ? item.agency_id.thumbnail
                  : require('@/assets/background0.png')
              "
              width="200"
              style="flex-grow: 1"
              :aspect-ratio="
                $vuetify.breakpoint.smAndDown ? 414 / 180 : 1654 / 455
              "
              class="rounded-lg rounded-b-0 position-absolute"
            />
            <editable-avatar
              :image="item.agency_id && item.agency_id.midThumbnail ? item.agency_id.midThumbnail : item.agency_image"
              :editable="false"
              :size="50"
              class="mt-5"
            />
          </div>
          <div class="url-button app-bold-font" @click="goToAgencyProfile(item)">
            {{ item.agency_id?.name }}
          </div>
        </template>
          <template v-slot:[`item.request_date`]="{ item }">
          <span>{{ formatDate(item.request_date) }}</span>
        </template>
        <template v-slot:[`item.response_date`]="{ item }">
          <span>{{ formatDate(item.response_date) }}</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  style="border-radius: 1px; border-color: #0A5B8A; border-style: solid; border-radius: 36px;"
                  small
                  class="ml-2"
                  @click.stop="onEditVolunteer(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="#0A5B8A" style="margin-top: 2px;" size="18"
                    >mdi-check</v-icon
                  >
                </v-btn>
              </template>
              Approve
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  style="border-radius: 1px; border-color: #0A5B8A; border-style: solid; border-radius: 36px;"
                  small
                  class="ml-2"
                  @click.stop="onDeleteVolunteer(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="#0A5B8A" style="margin-top: 2px;" size="18"
                    >mdi-close</v-icon
                  >
                </v-btn>
              </template>
              Reject
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
      <confirm-delete-dialog
        :deleteDialog="deleteDialog"
        :onClose="onCloseConfirmDelete"
        :onConfirm="onConfirmDelete"
      />
    </v-container>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import EditableAvatar from "@/components/EditableAvatar.vue";
import moment from "moment";
import ConfirmDeleteDialog from "@/components/common/ConfirmDeleteDialog.vue";
export default {
  components: {
    EditableAvatar,
    ConfirmDeleteDialog,
  },
  data() {
    return {
      loading: false,
      headers: [
        { text: "Member", value: "image" },
        { text: "Agency", value: "agency_name" },
        { text: "Status", value: "response_status" },
        { text: "Requested Date", value: "request_date" },
        { text: "Approved Date", value: "response_date" },
        // { text: "Actions", value: "actions",sortable: false }
      ],
      deleteDialog: false,
      selected: null,
      expanded: [],
      editDialog: false,
      viewDialog: false,
      memberList:[]
    };
  },
  computed: {
    ...mapGetters({
      volunteers: "volunteer/getAllVolunteers",
      profile: "auth/getProfile",
      profileType: "auth/getType",
      timezone: "usersetting/getTimeZone"
    }),
    formattedMemberList() {
      return this.members.map(member => ({
        ...member,
        request_date: member.request_date ? this.formatDate(member.request_date) : '-',
        response_date: member.response_date ? this.formatDate(member.response_date) : '-'
      }));
    }
  },
  methods: {
    ...mapActions( {
      fetchAllMembers: "members/allMembersList"
    }),
    onSelectClient(item) {
      console.log(item, "item");
    },
    handleDialogOpen(volunteerId) {
      // Fetch volunteer and set selectedVolunteer here
      this.selectedVolunteer = volunteerId;
      this.viewDialog = true;
    },
    fetchVolunteerById(id) {
      // Implement your API call to fetch the volunteer by ID
      return this.$store.dispatch("volunteer/viewVolunteer", id);
    },
    handleDialogClose() {
      this.viewDialog = false;
    },
     formatDate(date) {
      return date ? moment(date).format("MM/DD/YYYY") : "-";
    },
    onloadMembers (){
        this.loading = true;
        this.fetchAllMembers()
            .then((res) => {
            this.memberList = res;
            this.loading = false;
            })
            .catch(error => {
            this.loading = false;
            if (error.response == undefined) {
                console.log(error);
            } else {
                this.showErrorMessage(error.response.data.message);
            }
            });
    },
    goToProfile(item) {
      this.$router.push({
        name: "volunteer-profile-details",
        params: { id: item.user_id }
      });
    },
    goToAgencyProfile(item) {
      this.$router.push({
        name: "agency-profile-details",
        params: { id: item.agency_id }
      });
    },
    onEditVolunteer(item) {
      this.selected = item;
      this.editDialog = true;
    },
    onCloseEditDialog() {
      this.editDialog = false;
    },
    onCloseViewDialog() {
      this.viewDialog = false;
    },
    ondetailedView(volunteerId) {
      this.selectedVolunteer = volunteerId;
      this.totalHours = this.volunteers.find(
        volunteer => volunteer._id === volunteerId
      ).spentHours;
      this.viewDialog = true;
    },
    onDeleteVolunteer(item) {
      this.selected = item;
      this.deleteDialog = true;
    },
    onCloseConfirmDelete() {
      this.deleteDialog = false;
    },
    onConfirmDelete() {
      this.deleteDialog = false;
      this.loading = true;
    },
  },

  mounted() {
    this.onloadMembers();
    if (this.profileType != "Admin") {
      this.$router.push({ name: "home" });
    }
    this.loading = true
  }
};
</script>
<style scoped>
.url-button {
    cursor: pointer;
    margin: 9px 16px 4px 58px;
    margin-top: 14px;
    text-align: justify;
}
</style>