<template>
  <div class="w-100 min-width-200 mx-0 d-flex flex-column align-center">
    <v-img
      src="@/assets/loading.gif"
      width="200px"
      contain
      v-show="!loadingMore && (loading || loadCompleted < events.length)"
    />
    <div
      v-show="loadingMore || (!loading && loadCompleted >= events.length)"
      class="w-100 max-width-650 event-feed-contents pb-5 px-5 align-center"
    >
      <create-event
        style="width: 100%"
        v-if="profileType == 'Volunteer' || profileType == 'Agency' || profileType == 'Company'"
      />
      <div v-else style="height: 20px"></div>
      <div
        v-for="(event, index) in events"
        :key="event._id"
        style="width: 100%"
      >
        <feed-event-card
          :_id="event._id"
          :eventIndex="events.length - index"
          :loadedData="loadedData"
          :loadMoreEvents="loadMoreEvents"
        />
        <ads-viewer v-if="index % 5 == 4" />
      </div>
      <v-progress-circular indeterminate color="amber" v-if="loadingMore" />
    </div>
    <div
      v-if="events.length == 0 && !loading"
      class="mt-6 d-flex flex-column justify-center align-center no-feed"
    >
      <div class="mb-3">
        <v-img
          :src="require('@/assets/svg/no-feed.svg')"
          style="width: 60px; height: 50px"
          contain
        />
      </div>
      No feeds available. Follow agencies or people to see their updates.
    </div>
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import AdsViewer from "../../components/ads/AdsViewer.vue";
import CreateEvent from "./CreateEvent.vue";
import FeedEventCard from "./FeedEventCard.vue";
export default {
  components: { CreateEvent, FeedEventCard, AdsViewer },
  data() {
    return {
      loading: false,
      errorMessage: null,
      snackbar: false,
      loadCompleted: -1,
      loadingMore: false
    };
  },
  methods: {
    ...mapActions("event", {
      fetchAllEvents: "fetchAllEvents",
      loadMore: "loadMoreEvents"
    }),
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
    loadedData() {
      this.loadCompleted++;
    },
    loadMoreEvents() {
      this.loadingMore = true;
      this.loadMore({ skip: this.events.length })
        .then(() => {
          setTimeout(() => {
            this.loadingMore = false;
          }, 2500);
        })
        .catch(error => {
          this.loadingMore = false;
          if (error.response == undefined) {
            console.log(error);
          } else {
            this.showErrorMessage(error.response.data.message);
          }
        });
    }
  },
  computed: {
    ...mapGetters({
      events: "event/getAllEvents",
      profileType: "auth/getType"
    })
  },
  mounted() {
    this.loading = true;
    this.loadCompleted = 0;
    this.fetchAllEvents()
      .then(() => {
        this.loading = false;
      })
      .catch(error => {
        this.loading = false;
        if (error.response == undefined) {
          console.log(error);
        } else {
          this.showErrorMessage(error.response.data.message);
        }
      });
  }
};
</script>
<style scoped>
.event-feed-contents {
  display: flex;
  flex-direction: column;
}

.no-feed {
  font-size: 18px;
  color: #fff;
}
</style>
