<template>

    <div class="container">
      <div class="d-flex flex-row w-100 justify-between head-title">
        <span class="white--text font-30 app-medium-font">  Members Register</span>
   
      </div>

      <v-card class="membr-card" >
        <v-card-title> Member in Agency </v-card-title>
        <v-card-text>
          <div style="font-family: 'Poppins-Medium'; color: #1e1e1e">
            You are already a member of an agency.
            <div v-if="!formattedMembersList.length">
              <v-skeleton-loader
                type="table"
                class="elevation-1"
                :loading="!formattedMembersList.length"
              ></v-skeleton-loader>
            </div>
            <div v-else>
              <v-data-table
                :headers="headers"
                :items="formattedMembers"
                class="elevation-1"
              >
                <template v-slot:top>
                  <!-- <v-toolbar flat>
                <v-toolbar-title>Agency Members List</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
              </v-toolbar> -->
                </template>
                <template v-slot:item.sno="{ index }">
                  <span>{{ index + 1 }}</span>
                </template>
                <template #item._id="{ item }">
                  <span>{{ item.name }}</span>
                </template>
              </v-data-table>
            </div>
          </div>
        </v-card-text>
      </v-card>
       <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
    </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";
export default {

  data() {
    return {
      selectedAgency: null,
      isToggled: false,
      regForm: true,
      errorMessage: null,
      snackbar: false,
      members: [],
      formattedMembersList: [],
      profiledata: null,
      isMember: null,
      loading: false,
      headers: [
        { text: "S.no", value: "sno" },
        { text: "Agency", value: "agency_id.name" },
        { text: "Requested Date", value: "request_date" },
        { text: "Responded Date", value: "response_date" },
        { text: "Status", value: "response_status" },
      ],
      errors: {
        selectedAgency: false,
        isToggled: false,
      },
    };
  },
  computed: {
    formattedMembers() {
      return this.formattedMembersList.map((member) => ({
        ...member,
        request_date: member.request_date
          ? this.formatDate(member.request_date)
          : "-",
        response_date: member.response_date
          ? this.formatDate(member.response_date)
          : "-",
      }));
    },
    ...mapState("auth", {
      profile: "profile",
    }),
  },
  methods: {
    ...mapActions({
      addMemberAction: "members/addMember",
      fetchAllAgency: "members/fetchAllAgency",
      fetchAgencyMembers: "members/fetchAgencyMembers",
    }),
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
    formatDate(date) {
      return moment(date).format("MM/DD/YYYY");
    },
    async addMember() {
      // Reset errors
      this.errors.selectedAgency = !this.selectedAgency;
      this.errors.isToggled = this.isToggled === false;

      // If no errors, submit the form
      if (!this.errors.selectedAgency && !this.errors.isToggled) {
        try {
          const params = {
            agency_id: this.selectedAgency,
            isMember: this.isToggled,
          };
          await this.addMemberAction(params);
          this.isMember = true;
          await this.onLoadMembers();
          this.loading = true;
        } catch (error) {
          this.loading = true
          this.showErrorMessage(
            error.message|| error || "An error occurred while adding the member."
          );
        } finally {
        this.loading = false; // Ensure loading stops even if an error occurs
      }
      }
    },
    async loadMembers() {
      try {
        this.loading = true;
        const response = await this.fetchAllAgency();
        this.members = response;
      } catch (error) {
        this.showErrorMessage(
          error.message || "An error occurred while loading members."
        );
      }
    },
    async onLoadMembers() {
      try {
        this.loading = true;
        const response = await this.fetchAgencyMembers();
        this.formattedMembersList = response;
        this.loading = false;
      } catch (error) {
        this.showErrorMessage(
          error.message || "An error occurred while loading members."
        );
      } finally {
        this.loading = false;
      }
    },
    goToHistory (){
        this.$router.push({ name: "members-history" });
    }
  },

  watch: {
    profile(newProfile) {
      this.profiledata = newProfile;
      this.isMember = newProfile.isMember;
    },
  },

  mounted() {
    if (this.profile) {
      this.isMember = this.profile.isMember;
    }
    this.fetchAgencyMembers()
      .then((members) => {
        this.formattedMembersList = members;
      })
      .catch((error) => {
        this.showErrorMessage(
          error.message || "An error occurred while fetching members."
        );
      });
    this.loadMembers();
    this.onLoadMembers();
  },
};
</script>
<style scoped>
.membr-card {
  margin-top: 5%;
}
.mem-title {
  margin-top: 1%;
  font-family: "Poppins-Medium" !important;
  margin-left: 17px;
}
/* .head-title{
  margin-top: 15%;
} */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>