<template>
    <div>
        <h1 class="mx-3 mt-8 mb-5 detail-title white--text font-30 app-medium-font">
            Detailed View
        </h1>
        <v-card>
            <v-card-title class="headline">
                <h3 class="main-title">{{ formattedDetails.title }}</h3>
                <h4 >{{ formattedDetails.subtitle }}</h4>
            </v-card-title>
            <v-card-subtitle>
                <span class="sub-title">{{ formattedDetails.description }}</span>
            </v-card-subtitle>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="6">
                        <div><strong>Start Date:</strong> {{ formattedDetails.start_date }}</div>
                    </v-col>
                    <v-col cols="12" md="6">
                        <div><strong>End Date:</strong> {{ formattedDetails.end_date }}</div>
                    </v-col>
                    <v-col cols="12" md="6">
                        <div><strong>Team Challenge Status:</strong> {{ formattedDetails.competition_status }}</div>
                    </v-col>
                    <v-col cols="12" md="6">
                        <div><strong>Target Points:</strong> {{ formattedDetails.target_points }}</div>
                    </v-col>
                </v-row>
                <div v-if="!formattedCompetitionTeams.length">
              <v-skeleton-loader
                type="table"
                class="elevation-1"
                :loading="!formattedCompetitionTeams.length"
              ></v-skeleton-loader>
            </div>
            <div v-else>
                <v-data-table
                    :headers="headers"
                    :items="formattedCompetitionTeams"
                    class="elevation-1"
                >
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title class="table-title">Participated Teams</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                            <v-dialog v-model="createDialog" max-width="500px">
                                <!-- Dialog content for creating a new competition -->
                            </v-dialog>
                        </v-toolbar>
                    </template>
                    <template v-slot:actions="{ item }">
                        <v-icon small class="mr-2" @click="editItem(item)">
                            mdi-pencil
                        </v-icon>
                        <v-icon small @click="deleteItem(item)">
                            mdi-delete
                        </v-icon>
                    </template>
                </v-data-table>
            </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";
export default {
    data() {
        return {
            loading: false,
            errorMessage: null,
            snackbar: false,
            competitions: [],
            notifications: [],
            errorNotifications: [],
            createDialog: false,
            editDialog: false,
            deleteDialog: false,
            competitionDetails: null,
            competitionTeams: [],
            headers: [
                    { text: "Invited Agencies", value: "agency_id.name" },
                    { text: "Response Date", value: "createdAt" },
                    { text: "Responded Date", value: "response_date" },
                    { text: "Response Status", value: "response_status" },
                    // { text: "Status", value: "status" },
                    { text: "Total Posts", value: "agency_post_count" },
                    { text: "Total Competitions", value: "competition_points" },
      ],
        }
    },
    computed: {
        formattedDetails() {
            if (!this.competitionDetails) {
                return {};
            }
            return {
                ...this.competitionDetails,
                start_date: this.competitionDetails.start_date ? this.formatDate(this.competitionDetails.start_date) : '-',
                end_date: this.competitionDetails.end_date ? this.formatDate(this.competitionDetails.end_date) : '-',
            };
        },
        formattedCompetitionTeams() {
            return this.competitionTeams.map((team) => ({
                ...team,
                response_date: this.formatDate(team.response_date),
                createdAt: this.formatDate(team.createdAt),
            }));
        },
        tabledateDetails(){
            if (!this.competitionDetails.competitionTeams) {
                return [];
            }
           return this.competitionDetails.competitionTeams.map((comp) => ({
                    ...comp,
                    response_date: comp.response_date ? this.formatDate(comp.response_date) : '-',
            }));
        },
        ...mapState("auth", {
            profile: "profile",
        }),
    },
    methods: {
        ...mapActions({
            respectiveCompetition: "competition/respectiveCompetition",
        }),

        loaddata() {
            const params = {
                competition_id: this.$route.params.id,
            }
         
            this.respectiveCompetition(params)
                .then(async (res) => {
                    this.loading = false;
                    this.competitionDetails = res;
                     this.competitionTeams = await res.competitionTeams || [];
                })
                .catch((error) => {
                    this.loading = false;
                    this.showErrorMessage(error.message);
                });
        },
            formatDate(date) {
             return moment(date).format("MM/DD/YYYY");
            },
    },
    mounted() {
      this.loaddata();
          this.respectiveCompetition(this.$route.params.id)
      .then((details) => {
        this.competitionDetails = details;
        this.competitionTeams = details.competitionTeams;
      })
      .catch((error) => {
        this.showErrorMessage(
          error.message || "An error occurred while fetching competition details."
        );
      });
    }
}
</script>

<style scoped>
.main-title{
    margin-top: 2px;
    color: black;
    font-size: 20px;
    font-family: 'Popins', sans-serif;
    text-transform: uppercase;
}
.sub-title{
    margin: 2px;
    color: rgb(98, 97, 97);
    font-size: 15px;
    font-family: 'Popins', sans-serif;
}
.table-title{
    font-size: 20px;
    font-family: 'Popins', sans-serif;
    text-transform: uppercase;
    font-weight:700;
}
</style>