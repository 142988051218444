<template>
  <div class="mt-5 mx-5 w-100 max-width-2000">
    <div class="d-flex flex-row justify-between white--text">
      <span class="font-20 app-medium-font">
        Responses
      </span>
      <div class="url-button" @click="goToDetails" v-if="need">
        View need details
      </div>
    </div>
    <v-data-table
      :headers="headersShift"
      :items="shifts"
      class="mt-3"
      show-select
      v-model="selectedShifts"
      item-key="_id"
      :hide-default-footer="$vuetify.breakpoint.xs"
    >
      <template v-slot:[`item.title`]="{ item }">
        {{ item.title ? item.title : `Shift ${shifts.indexOf(item) + 1}` }}
      </template>
      <template v-slot:[`item.begins`]="{ item }">
        {{ formatDate(item.start_date) }}
      </template>
      <template v-slot:[`item.capacity`]="{ item }">
        {{
          item.responses
            ? item.responses.filter(r => r.status != "Rejected").length
            : 0
        }}
        of
        {{ item.capacity }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div style="width: 65px">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                style="border-radius: 1px; border-color: #0A5B8A; border-style: solid; border-radius: 36px;"
                small
                v-bind="attrs"
                v-on="on"
                @click.stop="onEditShift(item)"
              >
                <v-icon color="#0A5B8A" style="margin-top: 2px;" size="18">
                  mdi-pencil
                </v-icon>
              </v-btn>
            </template>
            Edit Shift
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                style="border-radius: 1px; border-color: #0A5B8A; border-style: solid; border-radius: 36px;"
                small
                class="ml-2"
                @click.stop="onDeleteShift(item)"
                v-bind="attrs"
                v-on="on"
                v-if="need && need.agency == profile._id"
              >
                <v-icon color="#0A5B8A" style="margin-top: 2px;" size="18"
                  >mdi-delete</v-icon
                >
              </v-btn>
            </template>
            Delete Shift
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
    <v-data-table
      :headers="headers"
      :items="selectedResponses"
      :items-per-page="$vuetify.breakpoint.xs ? -1 : 15"
      :loading="loading"
      class="elevation-0 mt-3"
      :class="{ 'mb-4': $vuetify.breakpoint.xs }"
      @dblclick:row="goToWorkDiary"
      :hide-default-footer="$vuetify.breakpoint.xs"
    >
      <template v-slot:[`item.image`]="{ item }">
        <div @click="goToProfile(item.volunteer ? item : item)" class="my-3">
          <editable-avatar
            :type="item.volunteer ? 'volunteer' : 'company'"
        :image="
          item.volunteer
            ? item.volunteer.thumbnail || item.volunteer.image
            : item.company
            ? item.company.thumbnail || item.company.image
            : ''
        "
        :editable="false"
        :size="40"
          />
        </div>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <div
          class="url-button"
          @click="goToProfile(item.volunteer ? item : item)"
          style="min-width: 100px;"
          v-if="item.volunteer || item.company"
        >
          {{ item.volunteer ? item.volunteer.first_name + ' ' + item.volunteer.last_name : item.company.name }}
        </div>
        <div v-else>
          Deleted User
        </div>
      </template>
      <template v-slot:[`item.note`]="{ item }">
        <div class="text-block">
          {{ item.note }}
        </div>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        <div style="min-width: 100px;">
          {{ getTimeAgo(item.createdAt) }} ago
        </div>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <div style="min-width: 60px;">
          <v-tooltip bottom v-if="item.status == 'Rejected' && item.reply">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                Rejected
              </div>
            </template>
            Reason: {{ item.reply }}
          </v-tooltip>
          <div v-else>
            {{ item.status }}
          </div>
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div style="min-width: 120px;">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                style="border-radius: 1px; border-color: #0A5B8A; border-style: solid; border-radius: 36px;"
                small
                @click.stop="onConfirm(item)"
                v-bind="attrs"
                v-on="on"
                :disabled="item.status == 'Confirmed'"
                :loading="confirmingResponse == item._id"
              >
                <v-icon color="#0A5B8A" style="margin-top: 2px;" size="18"
                  >mdi-check</v-icon
                >
              </v-btn>
            </template>
            Confirm
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                style="border-radius: 1px; border-color: #0A5B8A; border-style: solid; border-radius: 36px;"
                small
                @click.stop="onReject(item)"
                v-bind="attrs"
                v-on="on"
                class="ml-3"
                :disabled="item.status == 'Rejected'"
              >
                <v-icon color="#0A5B8A" style="margin-top: 2px;" size="18"
                  >mdi-close</v-icon
                >
              </v-btn>
            </template>
            Reject
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                style="border-radius: 1px; border-color: #0A5B8A; border-style: solid; border-radius: 36px;"
                small
                @click.stop="goToDiary(item)"
                v-bind="attrs"
                v-on="on"
                class="ml-3"
              >
                <v-icon color="#0A5B8A" style="margin-top: 2px;" size="18"
                  >mdi-calendar-text</v-icon
                >
              </v-btn>
            </template>
            Work Hours
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
    <create-shift
      v-if="need && createShiftDialog"
      :dialog="createShiftDialog"
      :need_id="need._id"
      :onCloseDialog="onCloseCreateShiftDialog"
      :onCreatedShift="onCreatedShift"
    />
    <edit-shift
      :dialog="editShiftDialog"
      :shift="selectedShift"
      :onCloseDialog="onCloseEditShiftDialog"
      :onSavedShift="onSavedShift"
      v-if="editShiftDialog"
    />
    <confirm-delete-shift-dialog
      :deleteDialog="deleteShiftDialog"
      :shift="selectedShift"
      :onClose="onCloseConfirmDelete"
      :onDeleted="onShiftDeleted"
    />
    <reject-response-dialog
      :dialog="rejectDialog"
      :need="need"
      :shiftTitle="selectedShiftTitle"
      :response="selectedResponse"
      :onCloseDialog="onCloseRejectDialog"
      :onRejected="onRejectedResponse"
    />
    <v-snackbar v-model="snackbar">
      {{ snackMessage }}
    </v-snackbar>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import dateFormat from "dateformat";
import EditableAvatar from "@/components/EditableAvatar.vue";
import CreateShift from "../needs/shift/CreateShift.vue";
import EditShift from "../needs/shift/EditShift.vue";
import ConfirmDeleteShiftDialog from "@/components/common/ConfirmDeleteShiftDialog.vue";
import RejectResponseDialog from "./RejectResponseDialog.vue";
export default {
  components: {
    EditableAvatar,
    CreateShift,
    EditShift,
    ConfirmDeleteShiftDialog,
    RejectResponseDialog
  },
  data() {
    return {
      loading: false,
      createDialog: false,
      selected: null,
      editDialog: false,
      deleteDialog: false,
      snackbar: false,
      snackMessage: null,
      headersShift: [
        { text: "Title", value: "title" },
        { text: "Begins", value: "begins" },
        { text: "Duration", value: "hours" },
        { text: "Open spots", value: "capacity" },
        { text: "Actions", value: "actions", sortable: false }
      ],
      headers: [
        { text: "", value: "image" },
        { text: "Name", value: "name" },
        { text: "Response Date", value: "createdAt" },
        { text: "Note", value: "note" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions" }
      ],
      shifts: [],
      need: null,
      selectedShifts: [],
      selectedShift: null,
      selectedResponses: [],
      selectedResponse: null,
      createShiftDialog: false,
      deleteShiftDialog: false,
      editShiftDialog: false,
      confirmingResponse: "",
      rejectDialog: false,
      selectedShiftTitle: ""
    };
  },
  methods: {
    ...mapActions({
      fetchAllResponses: "response/fetchAllResponses",
      confirmResponse: "response/confirmResponse",
      rejectResponse: "response/rejectResponse",
      deleteResponse: "response/deleteResponse",
      fetchAllShifts: "need/fetchAllShifts",
      getNeedDetails: "need/getNeedDetails"
    }),
    onCreateNeed() {
      this.createDialog = true;
    },
    onCloseCreateDialog() {
      this.createDialog = false;
    },
    onConfirm(response) {
      if (response.volunteer) {
        this.confirmingResponse = response._id;
        this.confirmResponse(response._id)
          .then(res => {
            this.confirmingResponse = "";
            for (var i = 0; i < this.responses.length; i++) {
              if (res._id == this.responses[i]._id) {
                this.responses[i].status = res.status;
                break;
              }
            }
            for (i = 0; i < this.shifts.length; i++) {
              for (var j = 0; j < this.shifts[i].responses.length; j++) {
                if (this.shifts[i].responses[j]._id == res._id) {
                  this.shifts[i].responses[j].status = res.status;
                  break;
                }
              }
            }
            // console.log(this.shifts);
            // this.shifts = [...this.shifts];
          })
          .catch(error => {
            this.confirmingResponse = "";
            console.log(error.response.data.message);
          });
      } else  if (response.company) {
        this.confirmingResponse = response._id;
        this.confirmResponse(response._id)
          .then(res => {
            this.confirmingResponse = "";
            for (var i = 0; i < this.responses.length; i++) {
              if (res._id == this.responses[i]._id) {
                this.responses[i].status = res.status;
                break;
              }
            }
            for (i = 0; i < this.shifts.length; i++) {
              for (var j = 0; j < this.shifts[i].responses.length; j++) {
                if (this.shifts[i].responses[j]._id == res._id) {
                  this.shifts[i].responses[j].status = res.status;
                  break;
                }
              }
            }
            // console.log(this.shifts);
            // this.shifts = [...this.shifts];
          })
          .catch(error => {
            this.confirmingResponse = "";
            console.log(error.response.data.message);
          });
      } else {
        this.loading = true;
        this.deleteResponse({ _id: response._id })
          .then(() => {
            this.loading = false;
            this.snackMessage = "This user is deleted";
            this.snackbar = true;
            this.getAllShifts();
          })
          .catch(error => {
            this.loading = false;
            console.log(error);
          });
      }
    },
    onReject(response) {
      console.log(response,'54645');
      if (response.volunteer) {
        this.selectedResponse = response;
        this.rejectDialog = true;
      }else if (response.company){
        this.selectedResponse = response;
        this.rejectDialog = true;
      } else {
        this.loading = true;
        this.deleteResponse({ _id: response._id })
          .then(() => {
            this.loading = false;
            this.snackMessage = "This user is deleted";
            this.snackbar = true;
            this.getAllShifts();
          })
          .catch(error => {
            this.loading = false;
            console.log(error);
          });
      }
    },
    onRejectedResponse(res) {
      this.rejectDialog = false;
      for (var i = 0; i < this.responses.length; i++) {
        if (res._id == this.responses[i]._id) {
          this.responses[i].status = res.status;
          this.responses[i].reply = res.reply;
          break;
        }
      }
      for (i = 0; i < this.shifts.length; i++) {
        for (var j = 0; j < this.shifts[i].responses.length; j++) {
          if (this.shifts[i].responses[j]._id == res._id) {
            this.shifts[i].responses[j].status = res.status;
            this.shifts[i].responses[j].reply = res.reply;
            break;
          }
        }
      }
    },
    getTimeAgo(oldTimeStr) {
      const oldTime = Date.parse(oldTimeStr);
      const time = new Date();
      const different = (time.getTime() - oldTime) / 1000;
      const month = (different / 3600 / 24 / 30) | 0;
      const days = (different / 3600 / 24) % 30 | 0;
      const hours = (different / 3600) % 24 | 0;
      const mins = (different / 60) % 60 | 0;
      if (month == 1) return `1 month`;
      if (month) return `${month} months`;
      if (days == 1) return `1 day`;
      if (days) return `${days} days`;
      if (hours == 1) return `1 hours`;
      if (hours) return `${hours} hours`;
      if (mins == 1) return `1 min`;
      if (mins) return `${mins} min`;
      return `less than 1 min`;
    },
    getAllShifts() {
      this.fetchAllShifts({ need_id: this.$route.query._id })
        .then(res => {
          this.shifts = res;
          this.selectedShifts = res;
          if (this.$route.query.selected_shift) {
            this.selectedShifts = this.selectedShifts.filter(
              s => s._id == this.$route.query.selected_shift
            );
          }
        })
        .catch(error => {
          console.log(error.response.data.message);
        });
    },
    goToProfile(item) {
      if (item.volunteer && item.volunteer.type === 'Volunteer') {
          let id = item.volunteer._id;
          let routeData = this.$router.resolve({
            name: "volunteer-profile-details",
            params: { id }
          });
        window.open(routeData.href, "_blank");
      } else if (item.company && item.company.type === 'Company') {
        let id = item.company._id;
          let routeData = this.$router.resolve({
            name: "company-profile-details",
            params: { id }
          });
        window.open(routeData.href, "_blank");

      }
    
    },
    formatDate(date) {
      return dateFormat(
        this.convertTZ(date, this.timezone),
        "mm/dd/yyyy hh:MM TT"
      );
    },
    convertTZ(date, tzString) {
      if (!date) return "";
      return new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString(
          "en-US",
          {
            timeZone: tzString ? tzString : "America/New_York"
          }
        )
      );
    },
    getNeed() {
      this.loading = true;
      this.getNeedDetails({ _id: this.$route.query._id })
        .then(need => {
          this.loading = false;
          this.need = need;
          if (
            this.profile &&
            this.profile._id &&
            need &&
            need.agency != this.profile._id
          ) {
            console.log(need.agency, this.profile._id);
            this.$router.push({
              name: "404"
            });
          }
        })
        .catch(error => {
          this.loading = false;
          if (error.response != undefined && error.response.status === 401) {
            this.tokenDialog = true;
          } else {
            console.log(error.response.data.message);
          }
        });
    },
    onEditNeed() {
      this.editDialog = true;
    },
    onCloseEditDialog() {
      this.editDialog = false;
    },
    onCloseCreateShiftDialog() {
      this.createShiftDialog = false;
    },
    onAddShift() {
      this.createShiftDialog = true;
    },
    onCreatedShift(shift) {
      this.createShiftDialog = false;
      this.shifts.push(shift);
    },
    onDeleteNeed(need) {
      this.selected = need;
      this.deleteDialog = true;
    },
    onDeletedNeed() {
      this.deleteDialog = false;
      this.$router.back();
    },
    onCloseConfirmDelete() {
      this.deleteDialog = false;
      this.deleteShiftDialog = false;
    },
    onEditShift(shift) {
      this.selectedShift = shift;
      this.editShiftDialog = true;
    },
    onDeleteShift(shift) {
      this.selectedShift = shift;
      this.deleteShiftDialog = true;
    },
    onCloseEditShiftDialog() {
      this.editShiftDialog = false;
    },
    onCloseRejectDialog() {
      this.rejectDialog = false;
    },
    onSavedShift(shift) {
      this.editShiftDialog = false;
      this.shifts = this.shifts.map(s => {
        if (s._id != shift._id) {
          return s;
        } else {
          return shift;
        }
      });
    },
    onShiftDeleted(shift) {
      this.shifts = this.shifts.filter(s => s._id != shift._id);
      this.deleteShiftDialog = false;
    },
    goToDetails() {
      this.$router.push({
        name: "agency-need-details",
        query: {
          _id: this.need._id
        }
      });
    },
    goToDiary(item) {
      if (this.need && item.volunteer) {
        this.$router.push({
          name: "agency-responses-diary",
          query: {
            need_id: this.need._id,
            volunteer_id: item.volunteer._id
          }
        });
      } else if (this.need && item.company) {
        this.$router.push({
          name: "agency-responses-diary",
          query: {
            need_id: this.need._id,
            company_id: item.company._id
          }
        });
        
      }
    },
    goToWorkDiary(event, { item }) {
      if (item.volunteer) {
        this.$router.push({
          name: "agency-responses-diary",
          query: {
            need_id: this.need._id,
            volunteer_id: item.volunteer._id
          }
        });
      }
    }
  },
  computed: {
    ...mapGetters({
      responses: "response/getAllResponses",
      timezone: "usersetting/getTimeZone"
    }),
    ...mapState("auth", {
      profile: "profile",
      profileType: "type"
    })
  },
  watch: {
    profile(newValue) {
      if (newValue && this.need && this.need.agency != newValue._id) {
        this.$router.push({
          name: "404"
        });
      }
    },
    selectedShifts(newValue) {
      this.selectedResponses = [];
      for (var i = 0; i < this.responses.length; i++) {
        for (var j = 0; j < newValue.length; j++) {
          if (this.responses[i].shift._id == newValue[j]._id) {
            this.selectedResponses.push(this.responses[i]);
            break;
          }
        }
      }
    },
    selectedResponse(newValue) {
      for (var i = 0; i < this.shifts.length; i++) {
        if (this.shifts[i]._id == newValue.shift._id) {
          this.selectedShiftTitle = newValue.shift.title
            ? newValue.shift.title
            : `Shift ${i + 1}`;
          break;
        }
      }
    }
  },
  mounted() {
    this.getNeed();
    this.loading = true;
    this.fetchAllResponses({ need_id: this.$route.query._id })
      .then(() => {
        this.loading = false;
        this.getAllShifts();
      })
      .catch(error => {
        this.loading = false;
        if (error.response != undefined && error.response.status === 401) {
          this.tokenDialog = true;
        } else {
          console.log(error.response.data.message);
        }
      });
  }
};
</script>
