<template>
  <router-view />
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("auth", {
      profile: "getProfile"
    })
  },
  watch: {
    "profile._id": function(newValue) {
      if (this.$route.params.id == undefined && newValue != undefined) {
        this.$router.replace({
          name: "company-profile-events",
          params: { id: newValue }
        });
      }
    },
    "$route.name": function(newValue) {
      if (
        newValue == "company-profile-details" &&
        !(this.$route.params.id == undefined && newValue != undefined)
      ) {
        this.$router.back();
      } else if (newValue == "company-profile") {
        this.$router.replace({
          name: "company-profile-events",
          params: { id: this.profile._id }
        });
      }
    }
  },
  mounted() {
    if (this.$route.params.id == undefined && this.profile._id != undefined) {
      this.$router.replace({
        name: "company-profile-events",
        params: { id: this.profile._id }
      });
    }
  }
};
</script>
