<template>
  <div class="admin-colour-list">
    <v-btn class="mb-4 d-flex justify-content-end add-colour" @click="openCreateDialog">
      <v-icon>mdi-plus</v-icon>
      Add Colour
    </v-btn>
    <v-card class="card_home rounded-xl w-100" elevation="8">
      <v-card-title>
        Admin Colour List
      </v-card-title>
      <v-card-text>
        <p>
          Pick the scores and select the colors.
        </p>
        <v-data-table
          :headers="headers"
          :items="colors"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Colors</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <!-- <template v-slot:item.colour_code="{ item }">
            <v-avatar size="24" :color="item.colour_code"></v-avatar>
              <span>{{ item.colour_code }}</span>
          </template> -->
          <template v-slot:item.colour_code="{ item }">
            <div class="d-flex align-center">
              <v-avatar size="24" :color="item.colour_code || '#FFFFFF'" class="mr-2"></v-avatar>
              <span>{{ item.colour_code || 'N/A' }}</span>
            </div>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn icon
              style="border-radius: 1px; border-color: #0a5b8a; border-style: solid; border-radius: 36px;"
              small
              @click="openEditDialog(item)"
            >
              <v-icon color="#0a5b8a" style="margin-top: 2px" size="18">mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon
              style="border-radius: 1px; border-color: #0a5b8a; border-style: solid; border-radius: 36px;"
              small
              @click="openDeleteDialog(item)"
              class="ml-2"
            >
              <v-icon color="#0a5b8a" style="margin-top: 2px" size="18">mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
    <admin-create-colour
      :dialog="dialog"
      :action="action"
      :colorData="selectedColor"
      :onClose="closeDialog"
      @action-complete="handleActionComplete"
    />
  </div>
</template>

<script>
import AdminCreateColour from './AdminCreateColour.vue';
import { mapActions } from "vuex";
export default {
  components: { AdminCreateColour },
  name: 'AdminColourList',
  data() {
    return {
      snackbar: false,
      errorMessage: '',
      dialog: false,
      action: '',
      selectedColor: {},
      colour_code: '',
      headers: [
        { text: 'Title', value: 'title' },
        { text: 'Color', value: 'colour_code' },
        { text: 'Score From', value: 'score_from' },
        { text: 'Score To', value: 'score_to' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      colors: [],
    };
  },
  methods: {
    ...mapActions( {
      allColours: "colour/getAllColor",
    }),
    openCreateDialog() {
      this.action = 'create';
      this.selectedColor = {};
      this.dialog = true;
    },
    openEditDialog(item) {
      this.action = 'edit';
      this.selectedColor = item;
      console.log(this.selectedColor);
      this.dialog = true;
    },
    openDeleteDialog(item) {
      this.action = 'delete';
      this.selectedColor = item;
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    handleActionComplete({ action, data }) {
      if (action === 'create') {
        this.colors.push(data);
      } else if (action === 'edit') {
        const index = this.colors.findIndex(color => color.id === data.id);
        if (index !== -1) {
          this.colors.splice(index, 1, data);
        }
      } else if (action === 'delete') {
        this.colors = this.colors.filter(color => color.id !== data.id);
      }
      this.snackbar = true;
      this.errorMessage = `${action.charAt(0).toUpperCase() + action.slice(1)} action completed successfully.`;
      this.loadColours();  
    },
    loadColours() {
      this.allColours().then((res) => {
        this.colors = res;     
      }).catch(() => {
        this.snackbar = true;
        this.errorMessage = 'Failed to load colours.';
      });
    }
 },
 mounted() {
    this.loadColours();
  }
};
</script>

<style scoped>
.admin-colour-list {
  padding: 20px;
  width: 100%;
}

.card_home {
  min-height: 100px;
  background-image: linear-gradient(#efecf4, #f6f4f9, #fbfafc);
  bottom: 0;
  width: 100%;
  margin: 0px;
  padding: 0px;
}
.add-colour{
    background-color: #0a5b8a;
    color: rgb(0, 0, 0);
    border-radius: 36px;
    padding: 10px 20px;
    margin-bottom: 20px;
}
</style>