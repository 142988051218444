<template>
  <keep-alive>
    <v-container
      fluid
      style="height: 100%; padding:0px; min-height: inherit; max-width: 1500px"
      class="d-flex flex-column"
    >
      <v-alert
        dismissible
        v-for="notification in notifications"
        :key="notification"
        style="margin-left: 100px; margin-right: 80px;"
        type="success"
      >
        {{ notification }}
      </v-alert>
      <v-alert
        dismissible
        v-for="notification in errorNotifications"
        :key="notification"
        style="margin-left: 100px; margin-right: 80px;"
        type="error"
      >
        {{ notification }}
      </v-alert>
      <div
        style="height: 100%; padding:0px; min-height: inherit; max-width: 1500px"
        class="d-flex flex-row"
      >
        <agency-left-section class="hidden-sm-and-down" />
    
         <div  
        class="comp-cards">
        <competition-list-vue  v-if="routername === 'competition'"/>
        <router-view />
      </div>
      <agency-right-section class="hidden-sm-and-down" v-if="routername === 'competition'"/>
      </div>
      <v-snackbar v-model="snackbar">
        {{ errorMessage }}
      </v-snackbar>
    </v-container>
  </keep-alive>
</template>
<script>
import {  mapState } from "vuex";
import AgencyLeftSection from "../agency/AgencyLeftSection.vue";
import AgencyRightSection from "../agency/AgencyRightSection.vue";
import CompetitionListVue from './CompetitionList.vue';
export default {
  components: {
    AgencyLeftSection,
    AgencyRightSection,
    CompetitionListVue
  },
  data() {
    return {
      loading: false,
      errorMessage: null,
      snackbar: false,
      verifyPhoneDialog: false,
      completeProfileDialog: false,
      causeDialog: false,
      interestsDialog: false,
      availabilitiesDialog: false,
      congratesDialog: false,
      notifications: [],
      errorNotifications: []
    };
  },
  methods: {
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
  },
  computed: {
  routername() {
   const routeName = this.$route.name; 
   return routeName; 
  },
    ...mapState("auth", {
      profile: "profile",

    })
  },

};
</script>
<style scoped>
    .comp-cards {
    width: 100%;
    height: auto;
    }
</style>