<template>
  <v-dialog v-model="value" max-width="500px">
    <v-card>
      <v-card-title class="headline">Private Profile</v-card-title>
      <v-card-text>
        This profile is private and cannot be viewed.
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'VolunteerPrivateDialogVue',
  props: {
    value: Boolean
  },
  methods: {
    close() {
      this.$emit('input', false);
    }
  }
};
</script>