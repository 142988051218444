<template>
  <keep-alive>
    <v-container
      fluid
      class="d-flex flex-column h-100 pa-0 min-height-inherit max-width-1500"
    >
      <div class="d-flex flex-row h-100 pa-0 min-height-inherit max-width-1500">
        <volunteer-left-section
          class="hidden-sm-and-down"
          v-if="profileType == 'Volunteer'"
        />
         <company-left-section
          class="hidden-sm-and-down"
          v-if="profileType == 'Company'"
        />
        <agency-left-section
          class="hidden-sm-and-down"
          v-else-if="profileType == 'Agency'"
        />
        <admin-left-section
          class="hidden-sm-and-down"
          v-else-if="profileType == 'Admin'"
        />
        <weather-contents-section />
      </div>
      <v-snackbar v-model="snackbar">
        {{ errorMessage }}
      </v-snackbar>
    </v-container>
  </keep-alive>
</template>
<script>
import { mapGetters } from "vuex";
import AdminLeftSection from "../admin/home/AdminHomeSideMenu.vue";
import AgencyLeftSection from "../agency/AgencyLeftSection.vue";
import VolunteerLeftSection from "../volunteer/VolunteerLeftSection.vue";
import WeatherContentsSection from "./WeatherContentsSection.vue";
import CompanyLeftSection from "../company/CompanyLeftSection.vue";
export default {
  components: {
    VolunteerLeftSection,
    AgencyLeftSection,
    WeatherContentsSection,
    AdminLeftSection,
    CompanyLeftSection
  },
  data() {
    return {
      loading: false,
      errorMessage: null,
      snackbar: false,
      notifications: [],
      errorNotifications: []
    };
  },
  computed: {
    ...mapGetters({
      profileType: "auth/getType"
    })
  }
};
</script>
<style scoped></style>
