<template>
  <div>
    <h1 class="mem-title white--text font-30 app-medium-font" v-if="!isMember">
      Registered Members
    </h1>
    <v-card class="membr-card">
      <v-card-title> Agency Members List </v-card-title>
      <v-card-text>
        <div style="font-family: 'Poppins-Medium'; color: #1e1e1e">
          Approve / Reject the request received.
          <v-data-table
            :headers="headers"
            :items="formattedMembers"
            class="elevation-1"
          >
            <template v-slot:top>
              <!-- <v-toolbar flat>
                <v-toolbar-title>Agency Members List</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
              </v-toolbar> -->
            </template>
            <template v-slot:item.sno="{ index }">
              <span>{{ index + 1 }}</span>
            </template>
            <template #item._id="{ item }">
              <span>{{ item.name }}</span>
            </template>
            <template #item.actions="{ item }">
              <v-tooltip bottom v-if="(item.response_status == 'Pending' || item.response_status == 'Accepted')">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    style="
                      border-radius: 1px;
                      border-color: #0a5b8a;
                      border-style: solid;
                      border-radius: 36px;
                      margin-right: 10px;
                    "
                    small
                    @click.stop="approveMember(item)"
                    v-bind="attrs"
                    v-on="on"
                    :disabled="item.response_status == 'Accepted'"
                    :loading="confirmingResponse == item._id"
                  >
                    <v-icon color="#0A5B8A" style="margin-top: 2px" size="18" 
                      >mdi-check</v-icon
                    >
                  </v-btn>
                </template>
                Confirm
              </v-tooltip>

              <v-tooltip bottom v-if="(item.response_status == 'Pending' || item.response_status == 'Accepted')">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    style="
                      border-radius: 1px;
                      border-color: #0a5b8a;
                      border-style: solid;
                      border-radius: 36px;
                    "
                    small
                    @click.stop="rejectMember(item)"
                    v-bind="attrs"
                    v-on="on"
                    :disabled="item.response_status == 'Rejected'"
                  >
                    <v-icon color="#0A5B8A" style="margin-top: 2px" size="18"
                      >mdi-close</v-icon
                    >
                  </v-btn>
                </template>
                Reject
              </v-tooltip>
              <template v-if="item.member_status == false && (item.response_status == 'Rejected')">
                No Actions
              </template>
            </template>
          </v-data-table>
        </div>
      </v-card-text>
    </v-card>
      <v-snackbar v-model="snackbar">
        {{ errorMessage }}
      </v-snackbar>
  </div>
  
</template>
<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      selectedAgency: null,
      isToggled: false,
      regForm: true,
      confirmingResponse: "",
      responseStatus: "",
      errorMessage: null,
      snackbar: false,
      members: [],
      profiledata: null,
      isMember: null,
      value: null,
      headers: [
        { text: "S.no", value: "sno" },
        { text: "Volunteer", value: "full_name" },
        { text: "Requested Date", value: "request_date" },
        { text: "Responded Date", value: "response_date" },
        { text: "Status", value: "response_status" },
        { text: "Actions", value: "actions" },
      ],
    };
  },
  computed: {
    formattedMembers() {
      return this.members.map((member) => ({
        ...member,
        full_name: member.user_id ? `${member.user_id.first_name} ${member.user_id.last_name}` : '-',
        request_date: member.request_date ? this.formatDate(member.request_date) : '-',
        response_date: member.response_date ? this.formatDate(member.response_date) : '-',
      }));
    },
    ...mapState("auth", {
      profile: "profile",
    }),
  },
  methods: {
    ...mapActions({
      fetchAgencyMembers: "members/fetchAgencyMembers",
      activateMember: "members/activateMember",
    }),
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
    async loadMembers() {
      try {
        this.loading = true;
        const response = await this.fetchAgencyMembers();
        this.members = response;
      } catch (error) {
        this.showErrorMessage(
          error.message || "An error occurred while loading members."
        );
      }
    },
    formatDate(date) {
      return moment(date).format("MM/DD/YYYY");
    },
    async approveMember(item) {
      const params = {
        member_id: item._id,
        responseStatus: "Accepted",
      };
      this.confirmingResponse = item._id;

      try {
        await this.activateMember(params);
        this.confirmingResponse = "";
        await this.loadMembers();
      } catch (error) {
        this.confirmingResponse = "";
        this.showErrorMessage(
          error || "An error occurred while approving the member."
        );
      }
    },
    async rejectMember(item) {
      const params = {
        member_id: item._id,
        responseStatus: "Rejected",
      };

      try {
        await this.activateMember(params);
        await this.loadMembers();
      } catch (error) {
        this.showErrorMessage(
          error.message || "An error occurred while rejecting the member."
        );
      }
    },
  },
  watch: {
    profile(newProfile) {
      this.profiledata = newProfile;
      this.isMember = newProfile.isMember;
    },
  },
  mounted() {
    this.fetchAgencyMembers()
      .then((members) => {
        this.members = members;
      })
      .catch((error) => {
        this.showErrorMessage(
          error.message || "An error occurred while fetching members."
        );
      });
    this.loadMembers();
  },
};
</script>
<style scoped>
.membr-card {
  margin-top: 5%;
}
.mem-title {
  margin-top: 1%;
  font-family: "Poppins-Medium" !important;
  margin-left: 17px;
}
</style>