import axiosInstance from "@/api/axios";

export default {
  namespaced: true,
  state: {
    members: [],
    competition:null,
    agencies: [],
    agencyMembers: [],
    adminMembers: [],
  },
  getters: {
    getAgencyFollowers(state) {
      return state.agencyFollowers;
    }
  },
  mutations: {
    agencies(state, data) {
      state.agencies = data;
    },
    agencyMembers(state, data) {
      state.agencyMembers = data;
    },
    setAgencyFollowers(state, data) {
      state.agencyFollowers = data;
    },
    ADD_MEMBER(state, member) {
        state.members.push(member);
      },
      SET_ADMIN_MEMBERS(state, members) { // Define the adminMembers mutation
        state.adminMembers = members;
      },
      activateMember(state, member) {
        state.members.push(member);
      },
  },
  actions: {
    fetchAllAgency({ commit, rootState }) {
        return new Promise((resolve, reject) => {
          axiosInstance(rootState.auth.token)
            .get("member/getVolunteerAgencies")
            .then(res => {
              if (res.data.agencies) {
                commit("agencies", res.data.agencies);
                resolve(res.data.agencies);
              } else {
                reject(new Error(res.data.message)); 
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      },
      fetchAgencyMembers({ commit, rootState }) {
        return new Promise((resolve, reject) => {
          axiosInstance(rootState.auth.token)
            .get("member/getAgencyMembers")
            .then(res => {
              if (res.data.members) {
                commit("agencyMembers", res.data.members);
                resolve(res.data.members);
              } else {
                reject(new Error(res.data.message)); 
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      },
    addMember({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
      axiosInstance(rootState.auth.token)
        .post("member/joinAsMember", params, {
        Authorization: rootState.auth.token
        })
        .then(res => {
        if (res.data.member) {
          commit("ADD_MEMBER", res.data.member);
          resolve(res.data.member);
        } else {
          reject(res.data.message);
        }
        })
        .catch(error => {
        reject(error);
        });
      });
    },
    activateMember({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
      axiosInstance(rootState.auth.token)
        .post("member/handleMembershipRequest", params, {
        Authorization: rootState.auth.token
        })
        .then(res => {
        if (res.data.data) {
          commit("activateMember", res.data.data);
          resolve(res.data.data);
        } else {
          reject(res.data.message);
        }
        })
        .catch(error => {
        reject(error);
        });
      });
    },
    allMembersList({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .get("users/allMembersList")
          .then(res => {
            if (res.data.members) {
              commit("SET_ADMIN_MEMBERS", res.data.members);
              resolve(res.data.members);
            } else {
              reject(new Error(res.data.message)); 
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  }
};
