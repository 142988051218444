<template>
  <v-container
    fluid
    style="height: 100%; margin:0px; padding:0px; min-height: inherit"
    class="d-flex flex-row"
  >
    <company-profile-left-section class="hidden-sm-and-down" />
    <company-profile-need-section />
  </v-container>
</template>
<script>
import CompanyProfileLeftSection from "./CompanyProfileLeftSection.vue";
import CompanyProfileNeedSection from "./CompanyProfileNeedSection.vue";
export default {
  components: { CompanyProfileLeftSection, CompanyProfileNeedSection }
};
</script>
