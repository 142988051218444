<template>
  <v-card class="d-flex flex-column mt-5" v-if="agency">
    <v-card-title class="d-flex align-center">
      <div v-text="agency.name" style="color: #472583" class="mr-3" />
      <icon-button
        :onClicked="onEditTitle"
        icon="mdi-pencil"
        text="Edit Name and Logo"
        v-if="profile._id == agency._id"
      />
    </v-card-title>
    <v-card-subtitle class="mt-4 d-flex align-center">
      <span class="subtitle mr-3">Causes</span>
      <icon-button
        :onClicked="onEditCause"
        icon="mdi-pencil"
        text="Edit Causes"
        v-if="profile._id == agency._id"
      />
    </v-card-subtitle>
    <v-card-text class="d-flex flex-wrap">
      <site-icon
        :name="icon"
        type="Cause"
        v-for="(icon, key) in agency.causes"
        :key="key"
        class="mr-3 mt-3"
      />
    </v-card-text>
    <v-card-subtitle class="mt-4 d-flex align-center">
      <span class="subtitle mr-3">Who we are</span>
      <icon-button
        :onClicked="onEditWhoWeAre"
        icon="mdi-pencil"
        text="Edit Who we are"
        v-if="profile._id == agency._id"
      />
    </v-card-subtitle>
    <v-card-text>
      <span v-text="agency.who_we_are" />
    </v-card-text>
    <v-card-subtitle class="d-flex align-center">
      <span class="subtitle mr-3">What we do</span>
      <icon-button
        :onClicked="onEditWhatWeDo"
        icon="mdi-pencil"
        text="Edit What we do"
        v-if="profile._id == agency._id"
      />
    </v-card-subtitle>
    <v-card-text>
      <span v-text="agency.what_we_do" />
    </v-card-text>
    <v-card-subtitle class="d-flex align-center">
      <span class="subtitle">Location</span>
      <icon-button
        :onClicked="onEditLocation"
        icon="mdi-pencil"
        text="Edit Location"
        v-if="profile._id == agency._id"
      />
    </v-card-subtitle>
    <v-card-text>
      <div class="ma-2">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="mr-2" v-bind="attrs" v-on="on" color="#fdbc3a"
              >mdi-map-marker</v-icon
            >
          </template>
          <span>Agency Address</span>
        </v-tooltip>
        <span>
          {{
            `${agency.address}${agency.address2 ? " " + agency.address2 : ""},
            ${agency.city}, ${agency.state} ${agency.zip}`
          }}
        </span>
      </div>
    </v-card-text>
    <v-card-subtitle class="d-flex align-center">
      <span class="subtitle mr-3">Contacts</span>
      <icon-button
        :onClicked="onEditContacts"
        icon="mdi-pencil"
        text="Edit Contact Information"
        v-if="profile._id == agency._id"
      />
    </v-card-subtitle>
    <v-card-text>
      <div class="ma-2">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="mr-2" v-bind="attrs" v-on="on" color="#fdbc3a"
              >mdi-phone-classic</v-icon
            >
          </template>
          <span>Agency Phone</span>
        </v-tooltip>
        {{ formatPhoneNumber(agency.phone) }}
      </div>
      <div class="ma-2">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="mr-2" v-bind="attrs" v-on="on" color="#fdbc3a"
              >mdi-email</v-icon
            >
          </template>
          <span>Agency Email</span>
        </v-tooltip>
        {{ agency.email }}
      </div>
      <div class="ma-2">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="mr-2" v-bind="attrs" v-on="on" color="#fdbc3a"
              >mdi-account-box</v-icon
            >
          </template>
          <span>Agency Contact</span>
        </v-tooltip>
        {{ agency.contact_person }}
      </div>
      <div class="ma-2">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="mr-2" v-bind="attrs" v-on="on" color="#fdbc3a"
              >mdi-account-box</v-icon
            >
          </template>
          <span>Agency Contact Title</span>
        </v-tooltip>
        {{ agency.contact_title }}
      </div>
    </v-card-text>
    <v-card-subtitle class="d-flex align-center">
      <span class="subtitle mr-3">Links</span>
      <icon-button
        :onClicked="onEditLinks"
        icon="mdi-pencil"
        text="Edit Links"
        v-if="profile._id == agency._id"
      />
    </v-card-subtitle>
    <v-card-text>
      <div class="ma-2">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="mr-2" v-bind="attrs" v-on="on" color="#fdbc3a"
              >mdi-link-variant</v-icon
            >
          </template>
          <span>Agency Website</span>
        </v-tooltip>
        <!-- <a :href="agency.website" target="_blank">Website</a> -->
         <a :href="agency.website" target="_blank">{{ getHostname(agency.website) }}</a>
      </div>
      <div class="ma-2" v-if="agency.facebook">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="mr-2" v-bind="attrs" v-on="on" color="#fdbc3a"
              >mdi-facebook</v-icon
            >
          </template>
          <span>Facebook</span>
        </v-tooltip>
        <a :href="agency.facebook" target="_blank">Facebook</a>
      </div>
      <div class="ma-2" v-if="agency.twitter">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="mr-2" v-bind="attrs" v-on="on" color="#fdbc3a"
              >mdi-twitter</v-icon
            >
          </template>
          <span>Twitter</span>
        </v-tooltip>
        <a :href="agency.twitter" target="_blank">Twitter</a>
      </div>
    </v-card-text>
    <v-card-subtitle class="d-flex align-center">
      <span
        class="subtitle mr-3"
        v-if="agency.photos.length != 0 || agency._id == profile._id"
        >Photos</span
      >
      <icon-button
        :onClicked="onAddPhoto"
        icon="mdi-plus"
        text="Add Photo"
        v-if="profile._id == agency._id"
      />
    </v-card-subtitle>
    <v-card-text>
      <agency-photo-viewer
        :agency_id="agency._id"
        :editable="agency._id == profile._id ? true : false"
        ref="viewer"
      />
    </v-card-text>
    <agency-profile-edit-title
      :dialog="editTitleDialog"
      :onClose="onCloseEditDialog"
      v-if="editTitleDialog"
    />
    <agency-profile-edit-causes-dialog
      :dialog="editCauseDialog"
      :onClose="onCloseEditDialog"
      v-if="editCauseDialog"
    />
    <agency-profile-edit-who-we-are
      :dialog="editWhoWeAreDialog"
      :onClose="onCloseEditDialog"
      v-if="editWhoWeAreDialog"
    />
    <agency-profile-edit-what-we-do
      :dialog="editWhatWeDoDialog"
      :onClose="onCloseEditDialog"
      v-if="editWhatWeDoDialog"
    />
    <agency-profile-edit-location
      :dialog="editLocationDialog"
      :onClose="onCloseEditDialog"
      v-if="editLocationDialog"
    />
    <agency-profile-edit-contacts
      :dialog="editContactsDialog"
      :onClose="onCloseEditDialog"
      v-if="editContactsDialog"
    />
    <agency-profile-edit-links
      :dialog="editLinksDialog"
      :onClose="onCloseEditDialog"
      v-if="editLinksDialog"
    />
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import AgencyPhotoViewer from "@/components/agency/AgencyPhotoViewer.vue";
import IconButton from "@/components/common/IconButton.vue";
import AgencyProfileEditTitle from "./edit/AgencyProfileEditTitle.vue";
import AgencyProfileEditCausesDialog from "./edit/AgencyProfileEditCausesDialog.vue";
import AgencyProfileEditWhoWeAre from "./edit/AgencyProfileEditWhoWeAre.vue";
import AgencyProfileEditWhatWeDo from "./edit/AgencyProfileEditWhatWeDo.vue";
import AgencyProfileEditLocation from "./edit/AgencyProfileEditLocation.vue";
import AgencyProfileEditContacts from "./edit/AgencyProfileEditContacts.vue";
import AgencyProfileEditLinks from "./edit/AgencyProfileEditLinks.vue";
import SiteIcon from "@/components/common/SiteIcon.vue";
export default {
  components: {
    AgencyPhotoViewer,
    IconButton,
    AgencyProfileEditTitle,
    AgencyProfileEditCausesDialog,
    AgencyProfileEditWhoWeAre,
    AgencyProfileEditWhatWeDo,
    AgencyProfileEditLocation,
    AgencyProfileEditContacts,
    AgencyProfileEditLinks,
    SiteIcon
  },
  data() {
    return {
      loading: false,
      editTitleDialog: false,
      editCauseDialog: false,
      editWhoWeAreDialog: false,
      editWhatWeDoDialog: false,
      editLocationDialog: false,
      editContactsDialog: false,
      editLinksDialog: false
    };
  },
  computed: {
    ...mapGetters({
      profile: "auth/getProfile",
      profileType: "auth/getType",
      agency: "agency/getAgency"
    })
  },
  methods: {
    ...mapActions("agency", {
      copyAgencyFromProfile: "copyFromProfile"
    }),
    onEditTitle() {
      this.editTitleDialog = true;
    },
    onEditCause() {
      this.editCauseDialog = true;
    },
    onEditWhoWeAre() {
      this.editWhoWeAreDialog = true;
    },
    onEditWhatWeDo() {
      this.editWhatWeDoDialog = true;
    },
    onEditLocation() {
      this.editLocationDialog = true;
    },
    onEditContacts() {
      this.editContactsDialog = true;
    },
    onEditLinks() {
      this.editLinksDialog = true;
    },
    getHostname(url) {
      try {
        return new URL(url).hostname;
      } catch (e) {
        return url;
      }
    },
    onCloseEditDialog() {
      this.editTitleDialog = false;
      this.editCauseDialog = false;
      this.editWhoWeAreDialog = false;
      this.editWhatWeDoDialog = false;
      this.editContactsDialog = false;
      this.editLinksDialog = false;
      this.editLocationDialog = false;
      this.copyAgencyFromProfile();
    },
    onAddPhoto() {
      this.$refs.viewer.onAddPhoto();
    },
    formatPhoneNumber(str) {
      let cleaned = ("" + str).replace(/\D/g, "");
      let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        let intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
          ""
        );
      }
      return str;
    }
  }
};
</script>
<style scoped>
.subtitle {
  font-size: 16px;
  font-family: "Poppins-SemiBold";
  color: #1e1e1e;
}
</style>
